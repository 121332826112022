import React, { ReactElement, useState } from "react";
import { PopupDialog } from "../components/PopupDialog";
import { ItemShared, MyPinelists, PinelistOverview, PinelistShared } from "../domain/types";
import { ActionClient } from "../domain/Client";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useMountEffect } from "../helpers";

interface Props {
  actionClient: ActionClient;
  pinelist: PinelistShared;
  itemToCopy: ItemShared | undefined;
  close: () => void;
}

export const CopyItemDialog = (props: Props): ReactElement => {
  const [listIdToCopyTo, setListIdToCopyTo] = useState<string>("");
  const [ownedPinelists, setOwnedPinelists] = useState<PinelistOverview[]>([]);

  const removeCurrentList = (pinelist: PinelistOverview): boolean => {
    return pinelist.id !== props.pinelist.id;
  };

  useMountEffect(() => {
    props.actionClient.getMyPinelists({
      onSuccess: (myPinelists: MyPinelists) => {
        setOwnedPinelists(myPinelists.asOwner.filter(removeCurrentList));
      },
      onError: () => {},
    });
  });

  const copyToList = (): void => {
    if (!props.itemToCopy) return;
    props.actionClient.addItem(
      {
        title: props.itemToCopy.title,
        details: props.itemToCopy.details,
        pinelist: listIdToCopyTo,
        isSection: false,
      },
      {
        onSuccess: () => {
          props.close();
        },
        onError: () => {},
      }
    );
  };

  return (
    <PopupDialog
      isOpen={props.itemToCopy !== undefined}
      close={props.close}
      title={`Copy ${props.itemToCopy?.title} to another list`}
      description={
        "Choose an unarchived list to copy this item to. This will not copy comments nor any interactions the givers have made on this item."
      }
      callToActionText={"Copy"}
      onAction={copyToList}
      disabledAction={listIdToCopyTo === ""}
    >
      <FormControl variant="filled" className="width-100">
        <InputLabel id="select-pinelist-label">Pinelist to copy to</InputLabel>
        <Select
          labelId="select-pinelist-label"
          id="select-pinelist"
          value={listIdToCopyTo}
          onChange={(event): void => {
            setListIdToCopyTo(event.target.value as string);
          }}
        >
          {ownedPinelists.map((it) => (
            <MenuItem key={it.id} value={it.id}>
              {it.name}
            </MenuItem>
          ))}
          {ownedPinelists.length === 0 && (
            <MenuItem value="" disabled>
              No other lists available
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </PopupDialog>
  );
};
