import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import * as serviceWorker from "./serviceWorker";
import { App } from "./App";
import { ThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      light: "#C1DFD5",
      main: "#047F57",
      dark: "#024A3E",
      contrastText: "#f8f8ff",
    },
    secondary: {
      light: "#888888",
      main: "#313131",
      dark: "#000",
      contrastText: "#f8f8ff",
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "#f1f1f1",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
