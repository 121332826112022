import { Menu, MenuProps, withStyles } from "@material-ui/core";
import React from "react";

export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: 180,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export const WideStyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: 360,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export const ExtraWideStyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: 440,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));
