import { Button } from "@material-ui/core";
import { copyToClipboard } from "../../helpers";
import { PopupDialog } from "../../components/PopupDialog";
import React, { ReactElement } from "react";

interface Props {
  isOpen: boolean;
  close: () => void;
  onRevoke: () => void;
  inviteLink: string;
}

export const InviteLinkDialog = (props: Props): ReactElement => (
  <PopupDialog
    isOpen={props.isOpen}
    close={props.close}
    title="Giver invite link"
    description="You can send this link to anyone. It will prompt them to sign-in or create an account with Pinelist. When they do so, they will be automatically added as a giver on this list."
    callToActionText="Close"
    secondaryActionText="Revoke invite link"
    onSecondaryAction={props.onRevoke}
    onAction={props.close}
  >
    <div className="fdr width-100 mbm">
      <div data-testid="invite-link" className="pvd phl bad bold">
        {props.inviteLink}
      </div>
      <Button
        style={{ boxShadow: "none", borderRadius: 0, border: "1px solid #ccc" }}
        onClick={(): void => {
          copyToClipboard(props.inviteLink);
        }}
        variant="contained"
      >
        Copy
      </Button>
    </div>
  </PopupDialog>
);
