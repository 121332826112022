import { Dispatch, Reducer } from "react";
import { User } from "./domain/types";

export enum UserActionType {
  LOGIN,
  LOGOUT,
  UPDATE,
}

export interface UserState {
  user: User | undefined;
  isAuth: boolean;
}

export interface UserAction {
  type: UserActionType;
  user: User | undefined;
}

export interface UserContextType {
  state: UserState;
  dispatch: Dispatch<UserAction>;
}

export type UserReducer = Reducer<UserState, UserAction>;

export const userReducer: UserReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionType.LOGIN:
      return {
        user: action.user,
        isAuth: true,
      };
    case UserActionType.LOGOUT:
      return {
        user: undefined,
        isAuth: false,
      };
    case UserActionType.UPDATE:
      return {
        user: action.user,
        isAuth: true,
      };
    default:
      throw new Error();
  }
};
