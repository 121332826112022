import React, { useEffect, KeyboardEvent, ReactElement } from "react";
import Linkify from "linkifyjs/react";

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (fun: () => void): void => useEffect(fun, []);

export const copyToClipboard = (text: string): Promise<void> => {
  return navigator.clipboard.writeText(text);
};

export const ifEnterKey = (e: KeyboardEvent, callback: () => void): void => {
  if (e.key === "Enter" && e.shiftKey) {
    return;
  }

  if (e.key === "Enter") {
    e.preventDefault();
    callback();
  }
};

export const newlinesToParagraphs = (text: string): ReactElement[] => {
  return text.split("\n").map((line, i) => <p key={i}>{line}</p>);
};

export const formatDescription = (text: string): ReactElement => {
  return <Linkify tagName="div">{newlinesToParagraphs(text)}</Linkify>;
};
