import React, { ReactElement } from "react";

interface Props {
  header: string;
  body: string;
}

export const EmptyState = (props: Props): ReactElement => {
  return (
    <div className="bg-light-green bradm pam mtd">
      <div className="text-l align-center mbs">{props.header}</div>
      {props.body}
    </div>
  );
};
