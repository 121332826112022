/* eslint-disable jsx-a11y/no-autofocus */

import React, { ChangeEvent, ReactElement, ReactNode } from "react";
import { Button, Icon, TextField } from "@material-ui/core";
import { ifEnterKey } from "../helpers";

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  submit: () => void;
  value: string;
  name: string;
  label: string;
  submitButtonText: string;
  secondaryElement?: ReactNode;
  error?: string;
  buttonType: "primary" | "secondary";
}

export const InlineTextField = (props: Props): ReactElement => {
  return (
    <div className="bad bradl">
      <TextField
        margin="dense"
        id={`${props.name}-inline`}
        name={`${props.name}-inline`}
        label={props.label}
        type="text"
        variant="filled"
        fullWidth
        error={!!props.error}
        helperText={props.error}
        style={{ marginTop: "0" }}
        onChange={props.onChange}
        onKeyDown={(e): void => ifEnterKey(e, props.submit)}
        placeholder=""
        value={props.value}
        InputProps={{ endAdornment: null, disableUnderline: true }}
      />
      <div className="fdr phxs pbs">
        <div className="mrs">
          <Button
            startIcon={<Icon>add_circle</Icon>}
            color="primary"
            variant={props.buttonType === "primary" ? "contained" : "outlined"}
            onClick={props.submit}
          >
            {props.submitButtonText}
          </Button>
        </div>
        {props.secondaryElement}
      </div>
    </div>
  );
};
