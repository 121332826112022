import React, { ReactElement, useContext, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { ActionClient } from "../domain/Client";
import { Giver, Group, PinelistOverview } from "../domain/types";
import { useMountEffect } from "../helpers";
import { ErrorPage, ErrorPageType } from "../ErrorPage";
import { UserTopBar } from "../components/UserTopBar";
import { Button, Icon, List, useMediaQuery } from "@material-ui/core";
import { Error } from "../domain/Error";
import { EmptyState } from "../components/EmptyState";
import { Header } from "../pinelists/Header";
import { SwipeTabs } from "../components/SwipeTabs";
import { UserListItem } from "../pinelists/as-owner/UserListItem";
import { AddUser } from "../pinelists/as-owner/AddUser";
import { LineItem } from "../pinelists/LineItem";
import { MediaQueries } from "../components/MediaQueries";
import { UserContext } from "../App";
import { useDocTitle } from "../hooks/useDocTitle";
import { AreYouSureDialog } from "../components/AreYouSureDialog";

interface Props extends RouteComponentProps {
  actionClient: ActionClient;
  id: string;
}

interface Props extends RouteComponentProps {
  actionClient: ActionClient;
  id: string;
}

export const GroupPage = (props: Props): ReactElement => {
  const [group, setGroup] = useState<Group | undefined>(undefined);
  const { state } = useContext(UserContext);
  const [idToDelete, setIdToDelete] = useState<string>("");
  const [showErrorPage, setShowErrorPage] = useState<ErrorPageType | "NONE">("NONE");
  const isXS = useMediaQuery(MediaQueries.isXS);
  useDocTitle(group?.name);

  useMountEffect(() => {
    props.actionClient.getGroupById(props.id, {
      onSuccess: setGroup,
      onError: (error: Error) => {
        const getErrorPageType = (): ErrorPageType => {
          switch (error) {
            case Error.USER_SIGNED_OUT:
              return "LOGGED_OUT";
            case Error.NOT_ALLOWED:
              return "NOT_ALLOWED";
            default:
              return "GENERIC";
          }
        };
        setShowErrorPage(getErrorPageType());
      },
    });
  });

  const onDeleteGroup = (): void => {
    props.actionClient.deleteGroup(idToDelete, {
      onSuccess: () => {
        setIdToDelete("");
        navigate("/home");
      },
      onError: (error: Error) => {
        setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  };

  const deleteMember = (memberId: string): void => {
    props.actionClient.deleteMember(
      {
        memberId: memberId,
        groupId: props.id,
      },
      {
        onSuccess: (group: Group) => {
          if (group.id === "deleted") {
            navigate("/home");
            return;
          }

          setGroup(group);
        },
        onError: (error: Error) => {
          setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
        },
      }
    );
  };

  const onAddedMember = (member: Giver): void => {
    if (group) {
      setGroup({
        ...group,
        members: [...group.members, member],
      });
    }
  };

  const getSharedListUrl = (pinelistOverview: PinelistOverview): string | undefined => {
    if (!state.user) return undefined;
    if (state.user.id === pinelistOverview.owner.id) {
      return `/pinelists/${pinelistOverview.id}/as/owner`;
    } else {
      return `/pinelists/${pinelistOverview.id}/as/giver`;
    }
  };

  if (showErrorPage !== "NONE") {
    return <ErrorPage type={showErrorPage} />;
  }

  if (!group) {
    return <></>;
  }

  const sharedListsColumn = (): ReactElement => (
    <div className="col-sm-7 col-lg-8 mtl">
      {!isXS && <h2 className="text-xl mtl">Pinelists shared with this group</h2>}
      <List>
        {group.pinelistsSharedWithGroup.length === 0 && (
          <EmptyState
            header="No items yet"
            body="When a group member adds this group as a giver to a list, the shared lists will show up here."
          />
        )}
        {group.pinelistsSharedWithGroup.map((pinelistOverview) => (
          <div key={pinelistOverview.id}>
            <LineItem
              textPrimary={pinelistOverview.name}
              textSecondary=""
              href={getSharedListUrl(pinelistOverview)}
              commentCount={undefined}
              claimedBy={undefined}
              ownedBy={pinelistOverview.owner}
              section="not-section"
              actions={[]}
            />
          </div>
        ))}
      </List>
    </div>
  );

  const membersColumn = (): ReactElement => (
    <div className="col-sm-5 col-lg-4">
      <div className="fdr fac mtl">
        <h2 className="text-l mrl">Members in this group</h2>
        <AddUser
          id={props.id}
          onAdded={onAddedMember}
          addUserFunction={props.actionClient.addMember}
          actionClient={props.actionClient}
          userType="member"
        />
      </div>
      <List data-testid="members">
        {group.members.map((member) => (
          <UserListItem key={member.id} giver={member} editable={true} onDelete={deleteMember} />
        ))}
        {group.members.length === 1 && (
          <EmptyState
            header="No other members yet"
            body="Add another member to your group to allow others to share lists."
          />
        )}
      </List>
      <div>
        <h2 className="text-l mrl mtl">Actions</h2>
        <List>
          <Button color="primary" onClick={(): void => setIdToDelete(group?.id)}>
            <Icon className="mrs">delete</Icon>
            Delete this group
          </Button>
        </List>
      </div>
    </div>
  );

  return (
    <>
      <UserTopBar actionClient={props.actionClient} />

      <div className="container pbxxl">
        <Header
          name={group.name}
          ownerName=""
          ownerId=""
          editable={true}
          editNameFunction={props.actionClient.editGroup}
          id={props.id}
          setNewCollection={setGroup}
          collectionType="Group"
          showIcon={true}
        />

        <SwipeTabs
          tabs={[
            {
              label: "Shared Lists",
              content: <div className="row">{sharedListsColumn()}</div>,
            },
            {
              label: "Members",
              content: <div className="row mtl">{membersColumn()}</div>,
            },
          ]}
        >
          <div className="row">
            {sharedListsColumn()}
            <div className="mtl">{membersColumn()}</div>
          </div>
        </SwipeTabs>
      </div>
      <AreYouSureDialog
        isOpen={idToDelete.length > 0}
        cancelCallback={(): void => setIdToDelete("")}
        primaryCallback={(): void => onDeleteGroup()}
        bodyText="If you delete this group, it will unshare any lists shared with this group. You and other
        group members may lose access to lists. All group data will be removed and unrecoverable.
        Are you sure you want to delete?"
      />
    </>
  );
};
