import React from "react";

export type CreateCollectionError = "NAME_TOO_LONG" | "NAME_REQUIRED" | "GENERIC";

type UseCreateCollectionErrorsReturn = {
  errorLookup: Record<CreateCollectionError, string>;
  errors: CreateCollectionError[];
  setErrors: (errors: CreateCollectionError[]) => void;
};

export const useCreateCollectionErrors = (
  collectionType: "Pinelist" | "Group"
): UseCreateCollectionErrorsReturn => {
  const [errors, setErrors] = React.useState<CreateCollectionError[]>([]);

  const CreateCollectionErrorLookup: Record<CreateCollectionError, string> = {
    NAME_TOO_LONG: `${collectionType} name must be 64 characters or fewer`,
    GENERIC: "Sorry, something went wrong. Please try again later",
    NAME_REQUIRED: `${collectionType} name is required`,
  };

  return {
    errorLookup: CreateCollectionErrorLookup,
    errors: errors,
    setErrors: setErrors,
  };
};
