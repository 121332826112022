import React, { ReactElement } from "react";
import { Button, Collapse, Icon, List, useMediaQuery } from "@material-ui/core";
import { EmptyState } from "../components/EmptyState";
import { ActionClient } from "../domain/Client";
import { PinelistOverview } from "../domain/types";
import { ErrorPageType } from "../ErrorPage";
import { MediaQueries } from "../components/MediaQueries";
import { LineItem } from "../pinelists/LineItem";
import { UserContext } from "../App";
import { Error } from "../domain/Error";

interface Props {
  isLoading: boolean;
  actionClient: ActionClient;
  archivedPinelists: PinelistOverview[];
  getAndSetLists: () => void;
  setShowErrorPage: (errorType: ErrorPageType) => void;
}

export const ArchivedPinelists = (props: Props): ReactElement => {
  const isXS = useMediaQuery(MediaQueries.isXS);
  const [archiveOpen, setArchiveOpen] = React.useState<boolean>(false);
  const { state } = React.useContext(UserContext);

  const onUnarchivePinelist = (id: string): void => {
    props.actionClient.unarchivePinelist(id, {
      onSuccess: () => {
        props.getAndSetLists();
      },
      onError: (error: Error) => {
        props.setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  };

  return (
    <div className="col-sm-12 mbxl mtl" data-testid="archived-lists">
      {!isXS && !props.isLoading && (
        <Button onClick={(): void => setArchiveOpen((prev) => !prev)} style={{ textTransform: "none" }}>
          <h2 className="text-xl mrd">Archived Lists</h2>
          {archiveOpen ? (
            <Icon fontSize="large">expand_less</Icon>
          ) : (
            <Icon fontSize="large">expand_more</Icon>
          )}
        </Button>
      )}
      <Collapse in={archiveOpen || isXS} timeout="auto" unmountOnExit className="mtl">
        {!props.isLoading && props.archivedPinelists.length === 0 && (
          <EmptyState
            header="Nothing here yet!"
            body="When you archive your lists or those shared with you, it will show up here."
          />
        )}
        <List>
          {props.archivedPinelists.map((pinelist) => {
            const isOwnedByCurrentUser = state.user?.id === pinelist.owner.id;
            const linkToPinelist = `/pinelists/${pinelist.id}/as/${isOwnedByCurrentUser ? "owner" : "giver"}`;

            return (
              <LineItem
                key={pinelist.id}
                textPrimary={pinelist.name}
                textSecondary=""
                href={linkToPinelist}
                section="not-section"
                commentCount={undefined}
                claimedBy={undefined}
                ownedBy={isOwnedByCurrentUser ? undefined : pinelist.owner}
                actions={[{ type: "UNARCHIVE", callback: (): void => onUnarchivePinelist(pinelist.id) }]}
              />
            );
          })}
        </List>
      </Collapse>
    </div>
  );
};
