import React, { ReactElement } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Button, Divider, MenuItem } from "@material-ui/core";
import { ActionClient } from "../domain/Client";
import { UserTopBar } from "../components/UserTopBar";
import { Notification } from "../domain/types";
import { useMountEffect } from "../helpers";
import dayjs from "dayjs";
import { useDocTitle } from "../hooks/useDocTitle";
import { BackHomeButton } from "../components/BackHomeButton";

interface Props extends RouteComponentProps {
  actionClient: ActionClient;
}

export const AccountNotificationsPage = (props: Props): ReactElement => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  useDocTitle("Notifications");

  useMountEffect(() => {
    props.actionClient.getNotifications({
      onSuccess: setNotifications,
      onError: () => {},
    });
  });

  const visitNotification = (notification: Notification): void => {
    navigate(notification.link);
    props.actionClient.markAsRead([notification.id], {
      onSuccess: (notifications: Notification[]) => setNotifications(notifications),
      onError: () => {},
    });
  };

  const markAllAsRead = (): void => {
    const unread = notifications.filter((it) => !it.read).map((it) => it.id);
    props.actionClient.markAsRead(unread, {
      onSuccess: (notifications: Notification[]) => setNotifications(notifications),
      onError: () => {},
    });
  };

  return (
    <>
      <UserTopBar
        actionClient={props.actionClient}
        disableNotifications={true}
        notificationsOverride={notifications}
      />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 mtl">
            <BackHomeButton />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 mtl">
            <h1 className="text-xxl">All Notifications</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12" data-testid="in-app-column">
            <div className="mtd mbs">
              <Button color="primary" onClick={markAllAsRead}>
                Mark all as read
              </Button>
            </div>
            {notifications
              .sort((a, b) => (dayjs(b.timestamp).isBefore(dayjs(a.timestamp)) ? -1 : 1))
              .map((it) => (
                <div key={it.id + it.read}>
                  <MenuItem
                    onClick={(): void => visitNotification(it)}
                    style={{ whiteSpace: "normal", backgroundColor: it.read ? "#f5f5f5" : "white" }}
                  >
                    <div className="fdr fac width-100">
                      <div className="pvxs" dangerouslySetInnerHTML={{ __html: it.text }} />
                      <div className="mla text-s text-grey">
                        {dayjs(it.timestamp).format("MMM D, YYYY HH:mm")}
                      </div>
                    </div>
                  </MenuItem>
                  <Divider />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
