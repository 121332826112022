import { Error } from "../domain/Error";

export type ResetPasswordEvent = "NEW_PASSWORD_ONCHANGE" | "CLICK_UPDATE" | "ON_SUCCESS" | "ON_ERROR";
export type ResetPasswordError = "PASSWORD_REQUIRED" | "GENERIC";

export type ResetPasswordFormValues = {
  newPassword: string;
};

const remove = (error: ResetPasswordError, errors: ResetPasswordError[]): ResetPasswordError[] => {
  return errors.filter((it) => it !== error);
};

const add = (error: ResetPasswordError, errors: ResetPasswordError[]): ResetPasswordError[] => {
  return [error, ...errors];
};

export const ResetPasswordErrorDecider = (
  event: ResetPasswordEvent,
  currentErrors: ResetPasswordError[],
  values: ResetPasswordFormValues,
  error: Error | null
): ResetPasswordError[] => {
  let newErrors = currentErrors;
  switch (event) {
    case "NEW_PASSWORD_ONCHANGE":
      break;
    case "CLICK_UPDATE":
      newErrors = remove("GENERIC", newErrors);
      if (!values.newPassword) {
        newErrors = add("PASSWORD_REQUIRED", newErrors);
      } else {
        newErrors = remove("PASSWORD_REQUIRED", newErrors);
      }
      break;
    case "ON_SUCCESS":
      newErrors = [];
      break;
    case "ON_ERROR":
      newErrors = add("GENERIC", newErrors);
      break;
  }

  return newErrors;
};
