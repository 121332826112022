export type AddItemEvent =
  | "TITLE_ONCHANGE"
  | "CLICK_ADD"
  | "ON_CLOSE"
  | "ON_SUCCESS"
  | "ON_ERROR"
  | "ON_START_STOP_EDITING";
export type AddItemError = "TITLE_TOO_LONG" | "TITLE_REQUIRED" | "GENERIC";

export type AddItemFormValues = {
  title: string;
  details: string;
};

const remove = (error: AddItemError, errors: AddItemError[]): AddItemError[] => {
  return errors.filter((it) => it !== error);
};

const add = (error: AddItemError, errors: AddItemError[]): AddItemError[] => {
  return [error, ...errors];
};

export const AddItemErrorDecider = (
  event: AddItemEvent,
  currentErrors: AddItemError[],
  values: AddItemFormValues
): AddItemError[] => {
  let newErrors = currentErrors;
  switch (event) {
    case "TITLE_ONCHANGE":
      newErrors = remove("TITLE_REQUIRED", newErrors);
      if (values.title.length > 64) {
        newErrors = add("TITLE_TOO_LONG", newErrors);
      } else {
        newErrors = remove("TITLE_TOO_LONG", newErrors);
      }
      break;
    case "CLICK_ADD":
      newErrors = remove("GENERIC", newErrors);
      if (!values.title) {
        newErrors = add("TITLE_REQUIRED", newErrors);
      }
      if (values.title.length > 64) {
        newErrors = add("TITLE_TOO_LONG", newErrors);
      }
      break;
    case "ON_START_STOP_EDITING":
      newErrors = [];
      break;
    case "ON_CLOSE":
      break;
    case "ON_SUCCESS":
      newErrors = [];
      break;
    case "ON_ERROR":
      newErrors = add("GENERIC", newErrors);
      break;
  }

  return newErrors;
};
