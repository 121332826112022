import React, { ReactElement } from "react";
import { ActionClient } from "../domain/Client";
import { FormControl, FormGroup, Switch } from "@material-ui/core";
import { NotificationPreferences } from "../domain/types";
import { useMountEffect } from "../helpers";

interface Props {
  actionClient: ActionClient;
}

type NotifPrefType = keyof NotificationPreferences;

export const NotifPrefLabels: Record<NotifPrefType, string> = {
  commentOnYourItem: "Comment on your item",
  commentOnYourList: "Comment on your list",
  commentOnItemYouCommentedOn: "Comment on item you also commented on",
  commentOnListYouCommentedOn: "Comment on list you also commented on",
  commentOnItemYouClaimed: "Comment on item you claimed",
  itemAddedToListYouAreGiverOn: "Item added to a list where you are a giver",
  itemYouClaimedDeleted: "Item you claimed was deleted",
  joinedYourListFromInvite: "Someone joined your list from an invite link",
  addedYouAsGiverToList: "Someone added you as a giver to their list",
  addedYouAsMemberToGroup: "Someone added you to a group",
  groupDeleted: "Someone deleted a group you're in",
};

const groups: Record<string, NotifPrefType[]> = {
  Comments: [
    "commentOnYourItem",
    "commentOnYourList",
    "commentOnItemYouCommentedOn",
    "commentOnListYouCommentedOn",
    "commentOnItemYouClaimed",
  ],
  Items: ["itemAddedToListYouAreGiverOn", "itemYouClaimedDeleted"],
  "People Added": ["joinedYourListFromInvite", "addedYouAsGiverToList"],
  Groups: ["addedYouAsMemberToGroup", "groupDeleted"],
};

export const NotificationPreferencesSection = (props: Props): ReactElement => {
  const [notifPrefs, setNotifPrefs] = React.useState<NotificationPreferences | undefined>(undefined);

  useMountEffect(() => {
    props.actionClient.getPreferences({
      onError: () => {},
      onSuccess: setNotifPrefs,
    });
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!notifPrefs) return;
    const newPreferences = { ...notifPrefs, [event.target.name]: event.target.checked };
    props.actionClient.savePreferences(newPreferences, {
      onError: () => {},
      onSuccess: setNotifPrefs,
    });
  };

  if (!notifPrefs) {
    return <></>;
  }

  return (
    <>
      <FormControl component="fieldset">
        <p className="mtz">Choose when you want to receive notifications:</p>
        <FormGroup>
          <table>
            <thead>
              <tr>
                <th className="label">Notification</th>
                <th>In-App</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groups).map((group) => (
                <>
                  <div className="bold-caps mtd" key={group}>
                    {group}
                  </div>
                  {groups[group].map((it) => (
                    <tr key={it}>
                      <td className="label">{NotifPrefLabels[it]}</td>
                      <td>
                        <Switch color="primary" checked={true} onChange={handleChange} name={it} disabled />
                      </td>
                      <td>
                        <Switch
                          color="primary"
                          checked={notifPrefs[it]}
                          onChange={handleChange}
                          name={it}
                          inputProps={{ "aria-label": NotifPrefLabels[it] }}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </table>
        </FormGroup>
      </FormControl>
    </>
  );
};
