/* eslint-disable jsx-a11y/no-autofocus */

import React, { ChangeEvent, ReactElement } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { Group, GroupOverview } from "../domain/types";
import { navigate } from "@reach/router";
import { ActionClient } from "../domain/Client";
import { Alert } from "@material-ui/lab";
import { MediaQueries } from "../components/MediaQueries";
import { useCreateCollectionErrors } from "../hooks/useCreateCollectionErrors";
import { CreateCollectionErrorDecider } from "./CreateCollectionErrorDecider";
import { EmptyState } from "../components/EmptyState";

interface Props {
  actionClient: ActionClient;
  groups: GroupOverview[];
}

export const MyGroups = (props: Props): ReactElement => {
  const [createDialogIsOpen, setCreateDialogIsOpen] = React.useState<boolean>(false);
  const [newGroupName, setNewGroupName] = React.useState<string>("");
  const { errors, setErrors, errorLookup } = useCreateCollectionErrors("Group");
  const isXS = useMediaQuery(MediaQueries.isXS);

  const openCreateDialog = (): void => {
    setCreateDialogIsOpen(true);
  };

  const closeCreateDialog = (): void => {
    setCreateDialogIsOpen(false);
  };

  const handleGroupNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newName = event.target.value;
    setNewGroupName(newName);
    setErrors(CreateCollectionErrorDecider("NAME_ONCHANGE", errors, newName));
  };

  const createNewGroup = (): void => {
    const newErrors = CreateCollectionErrorDecider("CLICK_CREATE", errors, newGroupName);
    setErrors(newErrors);

    if (newErrors.length > 0) {
      return;
    }

    props.actionClient.createGroup(newGroupName, {
      onSuccess: (group: Group) => {
        setErrors(CreateCollectionErrorDecider("ON_SUCCESS", errors, newGroupName));
        navigate(`/groups/${group.id}`);
      },
      onError: () => {
        setErrors(CreateCollectionErrorDecider("ON_ERROR", errors, newGroupName));
      },
    });
  };

  return (
    <div className={isXS ? "col-xs-12" : "mtl"}>
      <div className="mtl mbd fdr fac">
        {!isXS && <h2 className="text-xl mrl">My groups</h2>}
        <Button
          startIcon={<Icon>add_circle</Icon>}
          color="primary"
          variant="outlined"
          onClick={openCreateDialog}
        >
          New group
        </Button>
      </div>

      {props.groups.length === 0 && (
        <EmptyState
          header="No groups yet!"
          body="When you create a group or get added to a group, it will show up here."
        />
      )}

      <List>
        {props.groups.map((group) => (
          <div key={group.id}>
            <a href={`/groups/${group.id}`} className="button-styled-href">
              <ListItem key={group.id} button>
                <ListItemText
                  primary={
                    <div className="fdr fac">
                      <Icon className="mrs">groups</Icon>
                      {group.name}
                    </div>
                  }
                />
              </ListItem>
            </a>
            <Divider />
          </div>
        ))}
      </List>

      <Dialog open={createDialogIsOpen} onClose={closeCreateDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create new group</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Groups are a way to easily share lists among a regular set of members. Enter a name for your
            group, and then you'll be able to add your friends!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="group-name"
            name="group-name"
            label="Group name"
            variant="filled"
            type="text"
            fullWidth
            value={newGroupName}
            onChange={handleGroupNameChange}
          />
          {errors.length > 0 && (
            <div className="ptd">
              <Alert severity="error">{errorLookup[errors[0]]}</Alert>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateDialog} color="primary">
            Cancel
          </Button>
          <Button id="create" onClick={createNewGroup} variant="contained" color="primary">
            Create group
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
