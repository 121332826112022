import React, { ReactElement } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { AuthClient } from "../domain/Client";
import { LandingTopBar } from "../components/LandingTopBar";
import { RegisterForm } from "./RegisterForm";
import { useDocTitle } from "../hooks/useDocTitle";
import { BackHomeButton } from "../components/BackHomeButton";

interface Props extends RouteComponentProps {
  authClient: AuthClient;
}

export const Register = (props: Props): ReactElement => {
  useDocTitle(undefined);

  return (
    <>
      <LandingTopBar />
      <div className="container">
        <div className="row">
          <div className="col-sm-offset-2 col-sm-8 mtd mbm">
            <BackHomeButton href="/" />
            <h1 className="text-xxl mtl">Create an account</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-4 col-sm-4">
            <RegisterForm authClient={props.authClient} />
            <div className="mtl">
              Already have an account? <Link to="/login">Log in.</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
