import React, { ReactElement, useState } from "react";
import { Button } from "@material-ui/core";
import { CommentBox } from "./CommentBox";
import { Comment, User } from "../../domain/types";
import { ActionClient } from "../../domain/Client";

interface Props {
  comments: Comment[];
  owner: User;
  pinelistId: string;
  actionClient: ActionClient;
  deleteCommentCallback: (commentId: string) => void;
}

export const CommentsList = (props: Props): ReactElement => {
  const [commentsToShow, setCommentsToShow] = useState<number>(3);

  const deleteComment = (commentId: string): void => {
    props.actionClient.deleteComment(
      {
        commentId: commentId,
        pinelistId: props.pinelistId,
      },
      {
        onSuccess: () => {
          props.deleteCommentCallback(commentId);
        },
        onError: () => {},
      }
    );
  };

  return (
    <div>
      {commentsToShow < props.comments.length && (
        <div className="mbs">
          <Button color="primary" onClick={(): void => setCommentsToShow(commentsToShow + 3)}>
            Show More
          </Button>
        </div>
      )}
      {props.comments.slice(-commentsToShow).map((comment) => (
        <CommentBox key={comment.id} comment={comment} owner={props.owner} deleteComment={deleteComment} />
      ))}
    </div>
  );
};
