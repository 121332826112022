import { Button, List, useMediaQuery } from "@material-ui/core";
import React, { CSSProperties, ReactElement, useContext, useEffect, useState } from "react";
import { Comment, ItemAsOwner, PinelistAsOwner } from "../../domain/types";
import { ActionClient } from "../../domain/Client";
import { ShowItemDialog } from "../ShowItemDialog";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { MediaQueries } from "../../components/MediaQueries";
import { LineItem } from "../LineItem";
import { CopyItemDialog } from "../CopyItemDialog";
import { AddItemDialog } from "./AddItemDialog";
import { AddItemInline } from "./AddItemInline";
import { AddItemContext } from "./AddItemContext";
import queryString from "query-string";
import { navigate, WindowLocation } from "@reach/router";

const boxShadowStyle =
  "  0px 0.3px 1.3px rgba(0, 0, 0, 0.012),\n" +
  "  0px 0.7px 3.1px rgba(0, 0, 0, 0.023),\n" +
  "  0px 1.4px 5.8px rgba(0, 0, 0, 0.031),\n" +
  "  0px 2.7px 10.3px rgba(0, 0, 0, 0.039),\n" +
  "  0px 5.2px 19.2px rgba(0, 0, 0, 0.047),\n" +
  "  0px 12px 46px rgba(0, 0, 0, 0.07)";

interface Props {
  actionClient: ActionClient;
  pinelist: PinelistAsOwner;
  items: ItemAsOwner[];
  deleteItem: (item: ItemAsOwner) => void;
  setCommentForItemCallback: (comment: Comment, id: string) => void;
  deleteCommentForItemCallback: (commentId: string, id: string) => void;
  reorderItems: (items: ItemAsOwner[]) => void;
  location?: WindowLocation;
}

type Params = { item: string };

export const ItemsColumn = (props: Props): ReactElement => {
  const [selectedItem, setSelectedItem] = useState<ItemAsOwner | undefined>(undefined);
  const { actions } = useContext(AddItemContext);
  const [copyDialogForItem, setCopyDialogForItem] = useState<ItemAsOwner | undefined>(undefined);
  const isXS = useMediaQuery(MediaQueries.isXS);

  useEffect(() => {
    if (!props.location?.search || !props.items) {
      return;
    }

    const params = { ...queryString.parse(props.location.search) } as Params;
    if (params["item"]) {
      const foundItem = props.items.find((it) => it.id === params["item"]);
      setSelectedItem(foundItem);
      navigate(props.location.pathname, { replace: true });
    }
  }, [props.items, props.location]);

  useEffect(() => {
    (function changeItemInBackgroundWhenSelectedItemIsOpen(): void {
      if (selectedItem !== undefined) {
        const updatedItem = props.items.find((it) => it.id === selectedItem.id);
        if (updatedItem) {
          setSelectedItem(updatedItem);
        }
      }
    })();
  }, [props.items, selectedItem]);

  const closeShowItemDialog = (): void => {
    setSelectedItem(undefined);
  };

  const closeCopyItemDialog = (): void => {
    setCopyDialogForItem(undefined);
  };

  const onCopy = (item: ItemAsOwner): void => {
    setSelectedItem(undefined);
    setCopyDialogForItem(item);
  };

  const reorder = (list: ItemAsOwner[], startIndex: number, endIndex: number): ItemAsOwner[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(props.items, result.source.index, result.destination.index);

    props.reorderItems(newItems);
  };

  const getItemStyle = (
    index: number,
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ): CSSProperties => {
    return {
      boxShadow: isDragging ? boxShadowStyle : "",
      ...draggableStyle,
      top: isDragging && isXS ? 160 + 50 * index : (draggableStyle as DraggingStyle).top,
    };
  };

  return (
    <div className="col-sm-7 col-lg-8 mtl">
      <AddItemInline addButtonType="primary" />
      <AddItemDialog />

      <div className="mtl mbs">
        <Button className="fdr width-100 fac" color="primary" onClick={(): void => actions.openDialog(true)}>
          <hr className="width-100 height-1 border-primary" />
          <div className="phd" style={{ whiteSpace: "nowrap", minWidth: "max-content" }}>
            Add Section
          </div>
          <hr className="width-100 height-1 border-primary" />
        </Button>
      </div>

      <div data-testid="items-column">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided): ReactElement => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {props.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot): ReactElement => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.id}
                        style={getItemStyle(index, snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <LineItem
                          textPrimary={item.title}
                          textSecondary={item.details}
                          item={item}
                          onClick={(): void => setSelectedItem(item)}
                          commentCount={item.comments.length}
                          claimedBy={undefined}
                          ownedBy={undefined}
                          section={item.isSection ? "editable-section" : "not-section"}
                          actions={[
                            { type: "COPY", callback: (): void => onCopy(item) },
                            { type: "DELETE", callback: (): void => props.deleteItem(item) },
                          ]}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <ShowItemDialog
        selectedItem={selectedItem}
        close={closeShowItemDialog}
        onAction={(): void => {}}
        callToAction=""
        actionClient={props.actionClient}
        pinelist={props.pinelist}
        setCommentCallback={(comment): void =>
          props.setCommentForItemCallback(comment, selectedItem?.id || "")
        }
        deleteCommentCallback={(commentId): void =>
          props.deleteCommentForItemCallback(commentId, selectedItem?.id || "")
        }
        giverView={false}
        allowSwitchingVisibility={false}
      />

      <CopyItemDialog
        actionClient={props.actionClient}
        pinelist={props.pinelist}
        itemToCopy={copyDialogForItem}
        close={closeCopyItemDialog}
      />
    </div>
  );
};
