import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { ReactElement } from "react";

interface Props {
  isOpen: boolean;
  cancelCallback: () => void;
  primaryCallback: () => void;
  bodyText: string;
}

export const AreYouSureDialog = (props: Props): ReactElement => {
  return (
    <Dialog open={props.isOpen} onClose={props.cancelCallback} aria-labelledby="delete-dialog-title">
      <DialogTitle id="delete-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancelCallback} color="primary">
          Don't delete
        </Button>
        <Button id="delete" onClick={props.primaryCallback} variant="contained" color="primary">
          Yes, delete it
        </Button>
      </DialogActions>
    </Dialog>
  );
};
