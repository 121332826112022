/* eslint-disable jsx-a11y/no-autofocus */

import { Button, Icon, useMediaQuery } from "@material-ui/core";
import { navigate } from "@reach/router";
import React, { ReactElement, useState } from "react";
import { EditNameRequest } from "../domain/types";
import { Username } from "../components/Username";
import { Observer } from "../domain/Client";
import { useCreateCollectionErrors } from "../hooks/useCreateCollectionErrors";
import { MediaQueries } from "../components/MediaQueries";
import { InlineEditField } from "./InlineEditField";
import { CreateCollectionErrorDecider } from "../home/CreateCollectionErrorDecider";
import { BackHomeButton } from "../components/BackHomeButton";

interface Props<T> {
  name: string;
  ownerName: string;
  ownerId: string;
  editable: boolean;
  showIcon?: boolean;
  id?: string;
  setNewCollection?: (updatedCollection: T) => void;
  editNameFunction?: (editNameRequest: EditNameRequest, observer: Observer<T>) => void;
  collectionType: "Group" | "Pinelist";
}

export const Header = <T extends unknown>(props: Props<T>): ReactElement => {
  const [editingName, setEditingName] = useState<string | undefined>(undefined);
  const { errors, setErrors, errorLookup } = useCreateCollectionErrors(props.collectionType);
  const isDesktopAndUp = useMediaQuery(MediaQueries.desktopAndUp);

  const startEditingName = (): void => {
    setEditingName(props.name);
  };

  const handleNameChange = (newName: string): void => {
    setEditingName(newName);
    setErrors(CreateCollectionErrorDecider("NAME_ONCHANGE", errors, newName));
  };

  const submitNameEdit = (): void => {
    if (!props.editNameFunction || !props.id || !editingName) return;

    const newErrors = CreateCollectionErrorDecider("CLICK_CREATE", errors, editingName);
    setErrors(newErrors);

    if (newErrors.length > 0) {
      return;
    }

    props.editNameFunction(
      {
        id: props.id,
        newName: editingName,
      },
      {
        onSuccess: (updatedCollection: T) => {
          setErrors(CreateCollectionErrorDecider("ON_SUCCESS", errors, editingName));
          if (props.setNewCollection) props.setNewCollection(updatedCollection);
          setEditingName(undefined);
        },
        onError: () => {
          setErrors(CreateCollectionErrorDecider("ON_ERROR", errors, editingName));
        },
      }
    );
  };

  const borderSize = isDesktopAndUp ? "5px" : "2px";

  return (
    <div className="row">
      <div className="col-xs-12 mtl">
        <BackHomeButton />
        <h1 className="text-xxl mtl">
          {!props.editable && <>{props.name}</>}
          {props.editable && editingName === undefined && (
            <div className="fdr fac">
              {props.showIcon && (
                <Icon style={{ fontSize: "80px" }} className="mrd">
                  groups
                </Icon>
              )}
              <button
                style={{ borderBottom: `${borderSize} solid white` }}
                className="cursor-text hover-edit"
                onClick={startEditingName}
              >
                {props.name}
              </button>
            </div>
          )}
          {props.editable && editingName !== undefined && (
            <div className="fdr fac">
              {props.showIcon && (
                <Icon style={{ fontSize: "80px" }} className="mrd">
                  groups
                </Icon>
              )}
              <InlineEditField
                label={`Edit ${props.collectionType.toLowerCase()} name`}
                onChange={handleNameChange}
                submit={submitNameEdit}
                defaultValue={editingName}
                textSize="text-xxl"
                errorText={errors.length > 0 ? errorLookup[errors[0]] : ""}
              />
            </div>
          )}
        </h1>

        {props.ownerId && props.ownerName && (
          <h3 className="fdr fac">
            <span className="mrs bold-caps">owner:</span>
            <Button
              onClick={(): void => {
                navigate(`/profile/${props.ownerId}`);
              }}
            >
              <Username username={props.ownerName} />
            </Button>
          </h3>
        )}
      </div>
    </div>
  );
};
