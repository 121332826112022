import React, { ReactElement } from "react";
import { ColoredAvatar, ColoredAvatarIcon } from "./ColoredAvatar";

interface Props {
  username: string;
  hideIcon?: boolean;
  color?: "primary" | "grey";
  isGroup?: boolean;
}

export const Username = (props: Props): ReactElement => {
  return (
    <span className={`fdr fac`}>
      {!props.hideIcon &&
        (props.isGroup ? (
          <ColoredAvatarIcon text="groups" size="small" color={props.color ?? "primary"} />
        ) : (
          <ColoredAvatar text={props.username} size="small" color={props.color ?? "primary"} />
        ))}
      <div className={`bold non-caps text-d ${props.color ? `text-${props.color}` : "text-primary"}`}>
        {props.username}
      </div>
    </span>
  );
};
