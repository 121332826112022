import React, { ReactElement, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Collapse, Icon, IconButton, Snackbar } from "@material-ui/core";
import { ActionClient } from "../domain/Client";
import { GroupOverview, MyPinelists, PinelistOverview } from "../domain/types";
import { useMountEffect } from "../helpers";
import { Alert } from "@material-ui/lab";
import { ErrorPage, ErrorPageType } from "../ErrorPage";
import { UserTopBar } from "../components/UserTopBar";
import { MyGroups } from "./MyGroups";
import { SwipeTabs, TabElement } from "../components/SwipeTabs";
import { Error } from "../domain/Error";
import { UserContext } from "../App";
import queryString from "query-string";
import { MyPinelistsColumn } from "./MyPinelistsColumn";
import { SharedWithMeColumn } from "./SharedWithMeColumn";
import { ArchivedPinelists } from "./ArchivedPinelists";
import { useDocTitle } from "../hooks/useDocTitle";

interface Props extends RouteComponentProps {
  actionClient: ActionClient;
}

type Params = { "reset-password": string; url: string };

export const Home = (props: Props): ReactElement => {
  const [asOwnerPinelists, setAsOwnerPinelists] = React.useState<PinelistOverview[]>([]);
  const [asGiverPinelists, setAsGiverPinelists] = React.useState<PinelistOverview[]>([]);
  const [archivedPinelists, setArchivedPinelists] = React.useState<PinelistOverview[]>([]);
  const [groups, setGroups] = React.useState<GroupOverview[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [showErrorPage, setShowErrorPage] = React.useState<ErrorPageType | "NONE">("NONE");
  const { state } = React.useContext(UserContext);
  const [emailAlertOpen, setEmailAlertOpen] = React.useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  useDocTitle("Home");

  useEffect(() => {
    if (state.user) {
      setEmailAlertOpen(!state.user.email);
    }
  }, [state.user]);

  useMountEffect(() => {
    if (!props.location?.search) {
      return;
    }

    const params = { ...queryString.parse(props.location.search) } as Params;
    if (params["reset-password"] === "success") {
      setSnackbarOpen(true);
    }
    if (params["url"]) {
      navigate(params["url"]);
    }
  });

  useMountEffect(() => {
    getAndSetLists();
    props.actionClient.getMyGroups({
      onSuccess: (groups: GroupOverview[]) => {
        setGroups(groups);
      },
      onError: (error: Error) => {
        setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  });

  const getAndSetLists = (): void => {
    props.actionClient.getMyPinelists({
      onSuccess: (myPinelists: MyPinelists) => {
        setAsOwnerPinelists(myPinelists.asOwner);
        setAsGiverPinelists(myPinelists.asGiver);
        setArchivedPinelists(myPinelists.archive);
        setIsLoading(false);
      },
      onError: (error: Error) => {
        setIsLoading(false);
        setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  };

  if (showErrorPage !== "NONE") {
    return <ErrorPage type={showErrorPage} />;
  }

  const getTabs = (): TabElement[] => {
    const tabs = [
      {
        label: "My pinelists",
        content: (
          <div className="row">
            <MyPinelistsColumn
              isLoading={isLoading}
              actionClient={props.actionClient}
              asOwnerPinelists={asOwnerPinelists}
              getAndSetLists={getAndSetLists}
              setShowErrorPage={setShowErrorPage}
            />
          </div>
        ),
      },
      {
        label: "Shared with me",
        content: (
          <div className="row">
            <SharedWithMeColumn
              isLoading={isLoading}
              actionClient={props.actionClient}
              asGiverPinelists={asGiverPinelists}
              getAndSetLists={getAndSetLists}
              setShowErrorPage={setShowErrorPage}
            />
          </div>
        ),
      },
      {
        label: "Archived Lists",
        content: (
          <div className="row">
            <ArchivedPinelists
              isLoading={isLoading}
              actionClient={props.actionClient}
              archivedPinelists={archivedPinelists}
              getAndSetLists={getAndSetLists}
              setShowErrorPage={setShowErrorPage}
            />
          </div>
        ),
      },
    ];

    tabs.push({
      label: "My groups",
      content: (
        <div className="row">
          <MyGroups actionClient={props.actionClient} groups={groups} />
        </div>
      ),
    });

    return tabs;
  };

  return (
    <>
      <UserTopBar actionClient={props.actionClient} />

      <div className="container">
        <Collapse in={emailAlertOpen} className="mtm">
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={(): void => setEmailAlertOpen(false)}
              >
                <Icon>close</Icon>
              </IconButton>
            }
          >
            You haven't provided an email address yet. This means you'll have no way to recover your account
            if you forget your password.
            <br />
            Head over to <a href="/account-settings">Account Settings</a> to update your email.
          </Alert>
        </Collapse>
        <SwipeTabs tabs={getTabs()}>
          <div className="row">
            <div className="col-sm-6">
              <MyPinelistsColumn
                isLoading={isLoading}
                actionClient={props.actionClient}
                asOwnerPinelists={asOwnerPinelists}
                getAndSetLists={getAndSetLists}
                setShowErrorPage={setShowErrorPage}
              />
              <div className="ptl">
                <MyGroups actionClient={props.actionClient} groups={groups} />
              </div>
            </div>

            <div className="col-sm-6">
              <SharedWithMeColumn
                isLoading={isLoading}
                actionClient={props.actionClient}
                asGiverPinelists={asGiverPinelists}
                getAndSetLists={getAndSetLists}
                setShowErrorPage={setShowErrorPage}
              />
            </div>
          </div>
          <div className="row mtxl">
            <ArchivedPinelists
              isLoading={isLoading}
              actionClient={props.actionClient}
              archivedPinelists={archivedPinelists}
              getAndSetLists={getAndSetLists}
              setShowErrorPage={setShowErrorPage}
            />
          </div>
        </SwipeTabs>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={(): void => {
          setSnackbarOpen(false);
          navigate("/home");
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={(): void => setSnackbarOpen(false)} severity="success">
          Password successfully reset
        </Alert>
      </Snackbar>
    </>
  );
};
