import React, { ReactElement } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { LandingTopBar } from "./components/LandingTopBar";
import addItems from "./assets/add-item.png";
import addGivers from "./assets/add-giver.png";
import inviteLink from "./assets/invite-link.png";
import asGiver from "./assets/as-giver.png";
import viewItem from "./assets/view-item.png";
import viewClaimedItem from "./assets/view-claimed-item.png";
import homePage from "./assets/home-page.png";
import notifications from "./assets/notifications.png";
import addGroupAsGiver from "./assets/add-group-as-giver.png";
import groupPage from "./assets/group-page.png";
import profile from "./assets/profile.png";
import { AboutBlock } from "./components/AboutBlock";
import { Button } from "@material-ui/core";
import { CopyrightFooter } from "./components/CopyrightFooter";
import { useDocTitle } from "./hooks/useDocTitle";

export const AboutPage = (props: RouteComponentProps): ReactElement => {
  useDocTitle("About");

  return (
    <>
      <div className="bg-almond">
        <LandingTopBar />

        <div className="container pvxl">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 align-center">
              <h1 className="text-xl text-navy mbl bold text-rust">Gift lists for organized people</h1>
              <h2 className="text-l text-rust">
                Pinelist gives you confidence that your wishes are heard and keeps gift-givers in sync about
                who's-buying-what
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container pvxl">
        <AboutBlock
          h1="Curate your list"
          description="As you add items to your list, you can add include links and details to guide gift-givers to the perfect item."
          mainImg={homePage}
          img1Header="Add items"
          img1={addItems}
          img2Header=""
          img2={""}
        />

        <AboutBlock
          h1="Share with others"
          description="Add users or groups to your list. They'll be able to view, claim, and comment on items."
          mainImg={inviteLink}
          img1Header="Add users as givers"
          img1={addGivers}
          img2Header="Givers get notified"
          img2={notifications}
        />

        <AboutBlock
          h1="Give with ease"
          description="Givers get a special view where they can claim items, and see what others have claimed."
          mainImg={asGiver}
          img1Header="View items"
          img1={viewItem}
          img2Header="View claimed items"
          img2={viewClaimedItem}
        />

        <AboutBlock
          h1="Group it up"
          description="Create groups of users to easily share within families, friend groups, and more."
          mainImg={groupPage}
          img1Header="Add groups to a list"
          img1={addGroupAsGiver}
          img2Header="Shared lists and groups with another user"
          img2={profile}
        />
      </div>

      <div className="bg-almond">
        <div className="container pvxl">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 align-center">
              <h2 className="text-xl text-navy mbl bold text-rust">Ready to streamline your gift-giving?</h2>
              <Button
                color="primary"
                variant="contained"
                onClick={(): void => {
                  navigate("/register");
                }}
                size="large"
              >
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </div>

      <CopyrightFooter />
    </>
  );
};
