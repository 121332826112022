import React, { ReactElement, ReactNode, useContext, useEffect } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Icon } from "@material-ui/core";
import { ActionClient } from "../domain/Client";
import { UserTopBar } from "../components/UserTopBar";
import { UserProfile } from "../domain/types";
import { UserContext } from "../App";
import { ProfileSection } from "./ProfileSection";
import { useDocTitle } from "../hooks/useDocTitle";
import { BackHomeButton } from "../components/BackHomeButton";

interface Props extends RouteComponentProps {
  actionClient: ActionClient;
  id: string;
}

export const ProfilePage = (props: Props): ReactElement => {
  const [profile, setProfile] = React.useState<UserProfile | undefined>(undefined);
  const { state } = useContext(UserContext);
  useDocTitle(profile?.user.username);

  useEffect(() => {
    if (!state.user) return;
    if (state.user.id === props.id) {
      navigate(`/home`);
      return;
    }

    props.actionClient.getUserProfile(props.id, {
      onSuccess: (res) => {
        setProfile(res);
      },
      onError: () => {},
    });
  }, [state.user, props.actionClient, props.id]);

  if (!profile) {
    return <></>;
  }

  return (
    <>
      <UserTopBar actionClient={props.actionClient} />
      <div className="container mbxxl">
        <div className="row">
          <div className="col-xs-12 mtl">
            <BackHomeButton />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 mtl">
            <div className="fdr fac text-xl">
              <Icon fontSize="inherit" className="mrs">
                account_circle
              </Icon>
              <span className="weight-400 mrd">Profile:</span>
            </div>
            <h1 className="text-xxl">
              <span>{profile.user.username}</span>
            </h1>
          </div>
        </div>

        <div className="row">
          <ProfileSection
            header="My lists - Shared with them"
            items={profile.sharedWithThem}
            url={(id: string): string => `/pinelists/${id}/as/owner`}
            emptyStateText={`When you add ${profile.user.username} as a Giver on one of your lists, it will show up here.`}
          />
          <ProfileSection
            header="Their lists - Shared with me"
            items={profile.sharedWithMe}
            url={(id: string): string => `/pinelists/${id}/as/giver`}
            emptyStateText={`When ${profile.user.username} adds you as a Giver on one of their lists, it will show up here.`}
          />
        </div>
        <div className="row mtm">
          <ProfileSection
            header="Shared with both of us"
            items={profile.sharedWithBothOfUs}
            showOwner={true}
            url={(id: string): string => `/pinelists/${id}/as/giver`}
            emptyStateText={`When someone adds both you and ${profile.user.username} to a list, it will show up here.`}
          />
          <ProfileSection
            header="Shared groups"
            items={profile.sharedGroups}
            url={(id: string): string => `/groups/${id}`}
            emptyStateText={`When you and ${profile.user.username} are in the same group, it will show up here.`}
            nameDisplayFn={(name: string): ReactNode => (
              <div className="fdr fac">
                <Icon className="mrs">groups</Icon>
                {name}
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};
