import React, { ReactElement } from "react";

interface Props {
  h1: string;
  description: string;
  mainImg: string;
  img1Header: string;
  img1: string;
  img2Header: string;
  img2: string;
}

export const AboutBlock = (props: Props): ReactElement => {
  return (
    <div className="row pvl">
      <div className="col-xs-12">
        <h1 className="text-xl text-navy mbl bold text-rust">{props.h1}</h1>
        <p className="text-m mbl">{props.description}</p>
        <div className="col-sm-5">
          <h2 className="text-l text-rust mbs">{props.img1Header}</h2>
          <img className="about-img" alt="" src={props.img1} />
          <h2 className="text-l text-rust mtm mbs">{props.img2Header}</h2>
          <img className="about-img" alt="" src={props.img2} />
        </div>
        <div className="col-sm-7">
          <img className="about-img" alt="" src={props.mainImg} />
        </div>
      </div>
    </div>
  );
};
