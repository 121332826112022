import React, { ReactElement } from "react";
import { Button, Icon } from "@material-ui/core";

interface Props {
  href?: string | undefined;
}

export const BackHomeButton = (props: Props): ReactElement => {
  return (
    <Button className="mbl" color="primary" href={props.href ?? "/home"} data-testid="button-link">
      <Icon className="mrs">arrow_back</Icon> Back home
    </Button>
  );
};
