import { Error } from "../../domain/Error";

export type AddUserEvent = "ON_CLOSE" | "ON_SUCCESS" | "ON_ERROR";
export type AddUserError = "GENERIC" | "USER_CONFLICT";

const add = (error: AddUserError, errors: AddUserError[]): AddUserError[] => {
  return [error, ...errors];
};

export const AddUserErrorDecider = (
  event: AddUserEvent,
  currentErrors: AddUserError[],
  error: Error | null
): AddUserError[] => {
  let newErrors = currentErrors;
  switch (event) {
    case "ON_CLOSE":
      newErrors = [];
      break;
    case "ON_SUCCESS":
      newErrors = [];
      break;
    case "ON_ERROR":
      if (error === Error.DATA_ALREADY_ADDED) {
        newErrors = add("USER_CONFLICT", newErrors);
      } else {
        newErrors = add("GENERIC", newErrors);
      }
      break;
  }

  return newErrors;
};
