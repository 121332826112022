import React, { ReactElement, useContext, useEffect, useState } from "react";
import { formatDescription } from "../helpers";
import { Divider } from "@material-ui/core";
import { PopupDialog } from "../components/PopupDialog";
import { Comment, ItemAsOwner, ItemShared, PinelistShared } from "../domain/types";
import { ActionClient } from "../domain/Client";
import { InlineEditField } from "./InlineEditField";
import { EditItemContext } from "./as-owner/EditItemContext";
import { AddItemErrorLookup } from "./as-owner/AddItemDialog";
import { CommentSection } from "./comments/CommentSection";

interface Props {
  actionClient: ActionClient;
  pinelist: PinelistShared;
  selectedItem: ItemShared | undefined;
  close: () => void;
  callToAction: string;
  onAction: () => void;
  setCommentCallback: (comment: Comment) => void;
  deleteCommentCallback: (commentId: string) => void;
  giverView: boolean;
  allowSwitchingVisibility: boolean;
  disabledAction?: boolean;
}

export const ShowItemDialog = (props: Props): ReactElement => {
  const [item, setItem] = useState<ItemShared | undefined>(props.selectedItem);
  const { state, actions } = useContext(EditItemContext);

  useEffect(() => setItem(props.selectedItem), [props.selectedItem]);

  const disabledAction = props.disabledAction ?? false;

  const onClose = (): void => {
    actions.stopEditing();
    props.close();
  };

  const setComment = (comment: Comment): void => {
    if (!item) return;
    setItem({
      ...item,
      comments: [...item.comments, comment],
    });
    props.setCommentCallback(comment);
  };

  const deleteComment = (commentId: string): void => {
    if (!item) return;
    setItem({
      ...item,
      comments: item.comments.filter((it) => it.id !== commentId),
    });
    props.deleteCommentCallback(commentId);
  };

  const startEditingTitle = (): void => {
    actions.setEditing({
      itemToEdit: props.selectedItem as ItemAsOwner,
      isSection: false,
      editingField: "title",
      dialogIsOpen: false,
    });
  };

  const startEditingDetails = (): void => {
    actions.setEditing({
      itemToEdit: props.selectedItem as ItemAsOwner,
      isSection: false,
      editingField: "details",
      dialogIsOpen: false,
    });
  };

  const titleEditingState = ((): "uneditable" | "display" | "edit" => {
    if (props.giverView) {
      return "uneditable";
    } else if (state.itemToEdit !== undefined && state.editingField === "title") {
      return "edit";
    } else {
      return "display";
    }
  })();

  const detailsEditingState = ((): "uneditable" | "display" | "edit" => {
    if (props.giverView) {
      return "uneditable";
    } else if (state.itemToEdit !== undefined && state.editingField === "details") {
      return "edit";
    } else {
      return "display";
    }
  })();

  const formattedDescription = ((): ReactElement => {
    if (!props.giverView && props.selectedItem?.details.length === 0) {
      return (
        <p>
          <i>Click to add details</i>
        </p>
      );
    }

    return formatDescription(props.selectedItem ? props.selectedItem.details : "");
  })();

  return (
    <PopupDialog
      isOpen={props.selectedItem !== undefined}
      close={onClose}
      description={""}
      callToActionText={props.callToAction}
      onAction={props.onAction}
      disabledAction={disabledAction}
      secondaryActionText="Close"
      hidePrimaryAction={!props.giverView}
    >
      <div data-testid="show-item">
        <h2 className="text-l weight-500">
          {titleEditingState === "uneditable" && <>{props.selectedItem?.title}</>}
          {titleEditingState === "display" && (
            <button
              style={{ borderBottom: `1px solid white` }}
              className="cursor-text hover-edit"
              onClick={startEditingTitle}
            >
              {props.selectedItem?.title}
            </button>
          )}
          {titleEditingState === "edit" && (
            <InlineEditField
              label="Edit item title"
              onChange={actions.changeTitle}
              submit={actions.submit}
              defaultValue={state.itemToEdit?.title ?? ""}
              textSize="text-l"
              errorText={state.errors.length > 0 ? AddItemErrorLookup[state.errors[0]] : ""}
            />
          )}
        </h2>

        <div className="mbd mts">
          {detailsEditingState === "uneditable" && <>{formattedDescription}</>}
          {detailsEditingState === "display" && (
            <button
              style={{ borderBottom: `1px solid white` }}
              className="cursor-text hover-edit width-100"
              onClick={startEditingDetails}
            >
              {formattedDescription}
            </button>
          )}
          {detailsEditingState === "edit" && (
            <InlineEditField
              label="Edit item details"
              onChange={actions.changeDetails}
              submit={actions.submit}
              defaultValue={state.itemToEdit?.details ?? ""}
              textSize="default"
              errorText=""
            />
          )}
        </div>

        <Divider />

        {item && (
          <CommentSection
            pinelistId={props.pinelist.id}
            comments={item.comments}
            owner={props.pinelist.owner}
            actionClient={props.actionClient}
            setCommentCallback={setComment}
            deleteCommentCallback={deleteComment}
            giverView={props.giverView}
            headerSize="h3"
            itemId={item.id}
            allowSwitchingVisibility={props.allowSwitchingVisibility}
          />
        )}
      </div>
    </PopupDialog>
  );
};
