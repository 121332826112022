import React, { ReactElement } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Button } from "@material-ui/core";
import landing from "./landing.png";
import { LandingTopBar } from "./components/LandingTopBar";
import { CopyrightFooter } from "./components/CopyrightFooter";
import { useDocTitle } from "./hooks/useDocTitle";

export const LandingPage = (props: RouteComponentProps): ReactElement => {
  useDocTitle(undefined);
  return (
    <>
      <div className="bg-almond height-85">
        <LandingTopBar dark={true} />

        <div className="container landing-container">
          <div className="row">
            <div className="col-sm-9" style={{ zIndex: 5 }}>
              <h1 className="text-xxl text-navy mbl landing-title">Gift lists for organized people</h1>
              <h2 className="text-l text-rust mbs">
                Complicated spreadsheets? Try <mark>simplicity</mark>.
              </h2>
              <h2 className="text-l text-rust mbs">
                <mark>Claim list items</mark> and see what others claimed.
              </h2>
              <h2 className="text-l text-rust mbs">
                No more mess-ups, <mark>get what you want</mark>.
              </h2>
              <h2 className="text-l text-navy bold mbl">Just the cleanest, simplest gift-list app.</h2>
            </div>
          </div>
          <Button
            color="primary"
            variant="contained"
            onClick={(): void => {
              navigate("/register");
            }}
            size="large"
          >
            Get Started
          </Button>
          <Button
            style={{ marginLeft: "0.5rem" }}
            color="primary"
            variant="outlined"
            onClick={(): void => {
              navigate("/about");
            }}
            size="large"
          >
            Learn More
          </Button>

          <div className="row">
            <div className="col-xs-9 col-sm-offset-3">
              <img className="splash-image" src={landing} alt="" />
            </div>
          </div>
        </div>
      </div>

      <CopyrightFooter />
    </>
  );
};
