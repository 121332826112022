import React, { ReactElement, ReactNode } from "react";
import { Tab, Tabs, useMediaQuery } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { MediaQueries } from "./MediaQueries";

interface Props {
  tabs: TabElement[];
  children?: ReactNode;
  includeDesktop?: boolean;
}

export interface TabElement {
  label: string;
  content: ReactElement;
}

export const SwipeTabs = (props: Props): ReactElement => {
  const isXS = useMediaQuery(MediaQueries.isXS);
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<unknown>, newIndex: number): void => {
    setTabIndex(newIndex);
  };

  const a11yTabProps = (index: number): Record<string, string> => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  if (isXS || props.includeDesktop) {
    return (
      <>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {props.tabs.map((tabElement, i) => (
            <Tab key={i} label={tabElement.label} {...a11yTabProps(i)} />
          ))}
        </Tabs>
        <SwipeableViews axis="x" index={tabIndex} onChangeIndex={setTabIndex}>
          {props.tabs.map((tabElement, i) => (
            <div key={i}>{tabElement.content}</div>
          ))}
        </SwipeableViews>
      </>
    );
  } else {
    return <>{props.children}</>;
  }
};
