import { UserTopBar } from "./UserTopBar";
import { BackHomeButton } from "./BackHomeButton";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { ActionClient } from "../domain/Client";
import { useDocTitle } from "../hooks/useDocTitle";
import { Button, Divider, Icon, List, ListItem, ListItemText, useMediaQuery } from "@material-ui/core";
import { MediaQueries } from "./MediaQueries";

interface Props {
  pageTitle: string;
  actionClient: ActionClient;
  navItems: Record<string, NavItem>;
  initialId: string;
}

type NavItem = {
  name: string;
  component: ReactNode;
};

export const LeftNavPage = (props: Props): ReactElement => {
  const [openItemId, setOpenItemId] = React.useState<string | undefined>(props.initialId);
  useDocTitle("Account Settings");
  const isTabletAndUp = useMediaQuery(MediaQueries.tabletAndUp);

  useEffect((): void => {
    if (isTabletAndUp) {
      setOpenItemId(openItemId ?? props.initialId);
    } else {
      setOpenItemId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabletAndUp]);

  const showBackground = (navItemId: string): boolean => {
    return isTabletAndUp && navItemId === openItemId;
  };

  const backButton = (): ReactNode => {
    if (isTabletAndUp || !openItemId) {
      return <BackHomeButton />;
    } else {
      return (
        <Button
          className="mbl"
          color="primary"
          data-testid="button-link"
          onClick={(): void => setOpenItemId(undefined)}
        >
          <Icon className="mrs">arrow_back</Icon> Back to Settings
        </Button>
      );
    }
  };

  const getPageTitle = (): string => {
    if (isTabletAndUp) {
      return props.pageTitle;
    }

    if (openItemId) {
      return props.navItems[openItemId].name;
    } else {
      return props.pageTitle;
    }
  };

  return (
    <>
      <UserTopBar actionClient={props.actionClient} />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 mtl">{backButton()}</div>
        </div>
        <div className="row">
          <div className="col-xs-12 mtl">
            <h1 className="text-xxl">{getPageTitle()}</h1>
          </div>
        </div>
        <div className="row">
          {(!openItemId || isTabletAndUp) && (
            <div className="col-sm-4 mtl">
              <List>
                {Object.keys(props.navItems).map((navItemId) => (
                  <div key={navItemId} className={showBackground(navItemId) ? "bg-light-green" : ""}>
                    <ListItem button onClick={(): void => setOpenItemId(navItemId)}>
                      <ListItemText
                        primary={<div className="fdr fac">{props.navItems[navItemId].name}</div>}
                      />
                      <Icon className="mrs">chevron_right</Icon>
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </div>
          )}
          {openItemId && (
            <div className="col-sm-8 mtl form-max-width">
              {isTabletAndUp && <h2 className="text-l mbl">{props.navItems[openItemId].name}</h2>}
              {props.navItems[openItemId].component}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
