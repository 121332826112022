import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import { Button, FormControl, Icon, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { navigate, RouteComponentProps } from "@reach/router";
import { AuthClient } from "../domain/Client";
import { User } from "../domain/types";
import { Error } from "../domain/Error";
import { UserContext } from "../App";
import { UserActionType } from "../UserContext";
import { Alert } from "@material-ui/lab";
import { RegisterError, RegisterErrorDecider } from "./RegisterErrorDecider";

interface Props extends RouteComponentProps {
  authClient: AuthClient;
  onRegister?: (user: User) => void;
}

const RegisterErrorLookup: Record<RegisterError, string> = {
  USERNAME_TOO_LONG: "Username must be 32 characters or fewer",
  USERNAME_PASSWORD_REQUIRED: "Username and password are required",
  USERNAME_CONFLICT: "Username is already taken",
  GENERIC: "Sorry, something went wrong. Please try again later",
};

export const RegisterForm = (props: Props): ReactElement => {
  const { dispatch } = React.useContext(UserContext);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<RegisterError[]>([]);

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    const newErrors = RegisterErrorDecider("CLICK_REGISTER", errors, { username, password }, null);
    setErrors(newErrors);

    if (newErrors.length > 0) {
      return;
    }

    props.authClient.register(username, password, {
      onSuccess: (user: User) => {
        setErrors(RegisterErrorDecider("ON_SUCCESS", errors, { username, password }, null));
        dispatch({ type: UserActionType.LOGIN, user: user });
        props.onRegister ? props.onRegister(user) : navigate("/home");
      },
      onError: (error: Error) => {
        setErrors(RegisterErrorDecider("ON_ERROR", errors, { username, password }, error));
      },
    });
  };

  const handleUsernameInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setUsername(event.target.value);
    setErrors(
      RegisterErrorDecider("USERNAME_ONCHANGE", errors, { username: event.target.value, password }, null)
    );
  };

  const handlePasswordInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
    setErrors(
      RegisterErrorDecider("PASSWORD_ONCHANGE", errors, { username, password: event.target.value }, null)
    );
  };

  const toggleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <div className="pbd">
          <TextField
            fullWidth
            label="Username"
            placeholder="Username"
            name="username"
            variant="filled"
            value={username}
            onChange={handleUsernameInput}
          />
        </div>
        <div className="pbd">
          <TextField
            fullWidth
            label="Password"
            placeholder="Password"
            name="password"
            variant="filled"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword}>
                    {showPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {errors.length > 0 && (
          <div className="pvd">
            <Alert severity="error">{RegisterErrorLookup[errors[0]]}</Alert>
          </div>
        )}
        <Button color="primary" fullWidth type="submit" variant="contained">
          Register
        </Button>
      </FormControl>
    </form>
  );
};
