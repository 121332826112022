import { ItemAsGiver, ItemAsOwner, ItemRequest } from "../../domain/types";
import React, { createContext, ReactElement, ReactNode, useState } from "react";
import { AddItemError, AddItemErrorDecider } from "./AddItemErrorDecider";
import { Observer } from "../../domain/Client";

export interface AddItemState {
  title: string;
  details: string;
  errors: AddItemError[];
  dialogIsOpen: boolean;
  isSection: boolean;
}

export interface AddItemActions {
  changeTitle: (title: string) => void;
  changeDetails: (details: string) => void;
  submit: () => void;
  openDialog: (isSection: boolean) => void;
  closeDialog: () => void;
}

export interface AddItemContextType {
  state: AddItemState;
  actions: AddItemActions;
}

const initialState: AddItemState = {
  title: "",
  details: "",
  errors: [],
  dialogIsOpen: false,
  isSection: false,
};

export const AddItemContext = createContext<AddItemContextType>({
  state: initialState,
  actions: {
    changeTitle: (): void => {},
    changeDetails: (): void => {},
    submit: (): void => {},
    openDialog: (): void => {},
    closeDialog: (): void => {},
  },
});

interface Props<T extends ItemAsGiver | ItemAsOwner> {
  addFunction: (itemRequest: ItemRequest, observer: Observer<T>) => void;
  pinelistId: string;
  children: ReactNode;
  onSuccessfulAdd: (item: T) => void;
}

export const AddItemProvider = <T extends ItemAsGiver | ItemAsOwner>(props: Props<T>): ReactElement => {
  const [state, setState] = useState<AddItemState>(initialState);

  const changeTitle = (title: string): void => {
    const errors = AddItemErrorDecider("TITLE_ONCHANGE", state.errors, { title, details: state.details });
    setState({ ...state, title, errors });
  };

  const changeDetails = (details: string): void => {
    setState({ ...state, details });
  };

  const closeDialog = (): void => {
    let newState = {};

    if (state.isSection) {
      newState = {
        title: "",
        details: "",
        isSection: false,
      };
    }

    setState({
      ...state,
      ...newState,
      dialogIsOpen: false,
      errors: AddItemErrorDecider("ON_CLOSE", state.errors, {
        title: state.title,
        details: state.details,
      }),
    });
  };

  const openDialog = (isSection: boolean): void => {
    setState({
      ...state,
      isSection,
      dialogIsOpen: true,
    });
  };

  const submit = (): void => {
    const newErrors = AddItemErrorDecider("CLICK_ADD", state.errors, {
      title: state.title,
      details: state.details,
    });
    setState({ ...state, errors: newErrors });

    if (newErrors.length > 0) {
      return;
    }

    const observer = {
      onSuccess: (item: T): void => {
        setState({
          ...state,
          errors: AddItemErrorDecider("ON_SUCCESS", state.errors, {
            title: state.title,
            details: state.details,
          }),
        });
        props.onSuccessfulAdd(item);
        setState({
          ...state,
          title: "",
          details: "",
          dialogIsOpen: false,
          isSection: false,
        });
      },
      onError: (): void => {
        setState({
          ...state,
          errors: AddItemErrorDecider("ON_ERROR", state.errors, {
            title: state.title,
            details: state.details,
          }),
        });
      },
    };

    props.addFunction(
      {
        title: state.title,
        details: state.details,
        pinelist: props.pinelistId,
        isSection: state.isSection,
      },
      observer
    );
  };

  return (
    <AddItemContext.Provider
      value={{
        state: state,
        actions: {
          changeTitle,
          changeDetails,
          submit,
          closeDialog,
          openDialog,
        },
      }}
    >
      {props.children}
    </AddItemContext.Provider>
  );
};
