import { Error } from "../domain/Error";

export type ChangeUsernameEvent = "USERNAME_ONCHANGE" | "CLICK_UPDATE" | "ON_SUCCESS" | "ON_ERROR";
export type ChangeUsernameError = "USERNAME_TOO_LONG" | "USERNAME_REQUIRED" | "GENERIC" | "USERNAME_CONFLICT";

export type ChangeUsernameFormValues = {
  newUsername: string;
};

const remove = (error: ChangeUsernameError, errors: ChangeUsernameError[]): ChangeUsernameError[] => {
  return errors.filter((it) => it !== error);
};

const add = (error: ChangeUsernameError, errors: ChangeUsernameError[]): ChangeUsernameError[] => {
  return [error, ...errors];
};

export const ChangeUsernameErrorDecider = (
  event: ChangeUsernameEvent,
  currentErrors: ChangeUsernameError[],
  values: ChangeUsernameFormValues,
  error: Error | null
): ChangeUsernameError[] => {
  let newErrors = currentErrors;
  switch (event) {
    case "USERNAME_ONCHANGE":
      newErrors = remove("USERNAME_CONFLICT", newErrors);
      if (values.newUsername.length > 32) {
        newErrors = add("USERNAME_TOO_LONG", newErrors);
      } else {
        newErrors = remove("USERNAME_TOO_LONG", newErrors);
      }
      break;
    case "CLICK_UPDATE":
      newErrors = remove("GENERIC", newErrors);
      if (!values.newUsername) {
        newErrors = add("USERNAME_REQUIRED", newErrors);
      } else {
        newErrors = remove("USERNAME_REQUIRED", newErrors);
      }
      break;
    case "ON_SUCCESS":
      newErrors = [];
      break;
    case "ON_ERROR":
      if (error === Error.DATA_ALREADY_ADDED) {
        newErrors = add("USERNAME_CONFLICT", newErrors);
      } else {
        newErrors = add("GENERIC", newErrors);
      }
      break;
  }

  return newErrors;
};
