import React, { ReactElement, useContext } from "react";
import { InlineEditField } from "./InlineEditField";
import { EditItemContext } from "./as-owner/EditItemContext";
import { ItemAsOwner, ItemShared } from "../domain/types";

interface Props {
  sectionItem: ItemShared | undefined;
  editable: boolean;
}

export const PinelistSection = (props: Props): ReactElement => {
  const { state, actions } = useContext(EditItemContext);

  const sectionEditingState = ((): "uneditable" | "display" | "edit" => {
    if (!props.editable) {
      return "uneditable";
    } else if (state.itemToEdit !== undefined && state.editingField === "section-title") {
      return "edit";
    } else {
      return "display";
    }
  })();

  const startEditingSectionTitle = (): void => {
    actions.setEditing({
      itemToEdit: props.sectionItem as ItemAsOwner,
      isSection: true,
      editingField: "section-title",
      dialogIsOpen: false,
    });
  };

  if (!props.sectionItem) return <></>;

  return (
    <>
      {sectionEditingState === "uneditable" && <h2 className="text-l">{props.sectionItem.title}</h2>}
      {sectionEditingState === "display" && (
        <h2 className="text-l">
          <button
            style={{ borderBottom: `1px solid white` }}
            className="cursor-text hover-edit"
            onClick={startEditingSectionTitle}
          >
            {props.sectionItem.title}
          </button>
        </h2>
      )}
      {sectionEditingState === "edit" && (
        <InlineEditField
          label="Edit section title"
          onChange={actions.changeTitle}
          submit={actions.submit}
          defaultValue={state.itemToEdit?.title ?? ""}
          textSize="text-l"
          errorText=""
        />
      )}
    </>
  );
};
