import { Error } from "../domain/Error";

export type RegisterEvent =
  | "USERNAME_ONCHANGE"
  | "PASSWORD_ONCHANGE"
  | "CLICK_REGISTER"
  | "ON_SUCCESS"
  | "ON_ERROR";
export type RegisterError =
  | "USERNAME_TOO_LONG"
  | "USERNAME_PASSWORD_REQUIRED"
  | "GENERIC"
  | "USERNAME_CONFLICT";

export type RegisterFormValues = {
  username: string;
  password: string;
};

const remove = (error: RegisterError, errors: RegisterError[]): RegisterError[] => {
  return errors.filter((it) => it !== error);
};

const add = (error: RegisterError, errors: RegisterError[]): RegisterError[] => {
  return [error, ...errors];
};

export const RegisterErrorDecider = (
  event: RegisterEvent,
  currentErrors: RegisterError[],
  values: RegisterFormValues,
  error: Error | null
): RegisterError[] => {
  let newErrors = currentErrors;
  switch (event) {
    case "USERNAME_ONCHANGE":
      newErrors = remove("USERNAME_CONFLICT", newErrors);
      if (values.username.length > 32) {
        newErrors = add("USERNAME_TOO_LONG", newErrors);
      } else {
        newErrors = remove("USERNAME_TOO_LONG", newErrors);
      }
      break;
    case "PASSWORD_ONCHANGE":
      break;
    case "CLICK_REGISTER":
      newErrors = remove("GENERIC", newErrors);
      if (!values.username || !values.password) {
        newErrors = add("USERNAME_PASSWORD_REQUIRED", newErrors);
      } else {
        newErrors = remove("USERNAME_PASSWORD_REQUIRED", newErrors);
      }
      break;
    case "ON_SUCCESS":
      newErrors = [];
      break;
    case "ON_ERROR":
      if (error === Error.DATA_ALREADY_ADDED) {
        newErrors = add("USERNAME_CONFLICT", newErrors);
      } else {
        newErrors = add("GENERIC", newErrors);
      }
      break;
  }

  return newErrors;
};
