import React, { ReactElement, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { ActionClient } from "../domain/Client";
import { Comment, ItemAsGiver, ItemAsOwner, PinelistAsGiver } from "../domain/types";
import { ErrorPage, ErrorPageType } from "../ErrorPage";
import { Header } from "./Header";
import { Divider, List, useMediaQuery } from "@material-ui/core";
import { UserContext } from "../App";
import { UserTopBar } from "../components/UserTopBar";
import { SwipeTabs } from "../components/SwipeTabs";
import { MediaQueries } from "../components/MediaQueries";
import { Error } from "../domain/Error";
import { ShowItemDialog } from "./ShowItemDialog";
import { CommentSection } from "./comments/CommentSection";
import { LineItem } from "./LineItem";
import { EmptyState } from "../components/EmptyState";
import queryString from "query-string";
import { UserListItem } from "./as-owner/UserListItem";
import { useDocTitle } from "../hooks/useDocTitle";
import { AddItemInline } from "./as-owner/AddItemInline";
import { AddItemProvider } from "./as-owner/AddItemContext";
import { AddItemDialog } from "./as-owner/AddItemDialog";

interface Props extends RouteComponentProps {
  actionClient: ActionClient;
  id: string;
}

type ClaimAction = {
  callToActionText: string;
  onAction: () => void;
  isDisabled: boolean;
};

type Params = { item: string };

interface SelectedItem extends ItemAsGiver {
  isOffList: boolean;
}

export const PinelistGiverPage = (props: Props): ReactElement => {
  const { state } = React.useContext(UserContext);
  const [pinelist, setPinelist] = useState<PinelistAsGiver | undefined>(undefined);
  const [showErrorPage, setShowErrorPage] = useState<ErrorPageType | "NONE">("NONE");
  const [selectedItem, setSelectedItem] = useState<SelectedItem | undefined>(undefined);
  const isXS = useMediaQuery(MediaQueries.isXS);
  useDocTitle(pinelist?.name);

  useEffect(() => {
    if (!props.location?.search || !pinelist) {
      return;
    }

    const params = { ...queryString.parse(props.location.search) } as Params;
    if (params["item"]) {
      const foundItem = pinelist.items.find((it) => it.id === params["item"]);
      setSelectedItem(foundItem ? { ...foundItem, isOffList: false } : undefined);
      navigate(props.location.pathname, { replace: true });
    }
  }, [pinelist, props.location]);

  useEffect(() => {
    props.actionClient.getPinelistByIdAsGiver(props.id, {
      onSuccess: (pinelist: PinelistAsGiver) => {
        setPinelist(pinelist);
      },
      onError: (error: Error) => {
        setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  }, [props.id, props.actionClient]);

  const closeShowItemDialog = (): void => {
    setSelectedItem(undefined);
  };

  const onSuccessfulAdd = (newItem: ItemAsGiver): void => {
    setPinelist((prev: PinelistAsGiver | undefined) => {
      if (!prev) return undefined;
      const newOffListItems = prev.offListItems ? [...prev.offListItems, newItem] : [newItem];
      return {
        ...prev,
        offListItems: newOffListItems,
      };
    });
  };

  const claimItem = (item: ItemAsGiver): void => {
    if (!pinelist) return;

    props.actionClient.claimItem(
      {
        item: item.id,
        pinelist: pinelist.id,
      },
      {
        onSuccess: (pinelist: PinelistAsGiver) => {
          setPinelist(pinelist);
          closeShowItemDialog();
        },
        onError: (error: Error) => {
          setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
        },
      }
    );
  };

  const unclaimItem = (item: ItemAsGiver): void => {
    if (!pinelist) return;
    props.actionClient.unclaimItem(
      {
        item: item.id,
        pinelist: pinelist.id,
      },
      {
        onSuccess: (pinelist: PinelistAsGiver) => {
          setPinelist(pinelist);
          closeShowItemDialog();
        },
        onError: (error: Error) => {
          setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
        },
      }
    );
  };

  const getClaimAction = (params: { offList: boolean | undefined }): ClaimAction => {
    if (selectedItem?.claimedBy === undefined) {
      return {
        callToActionText: "Claim this item",
        onAction: (): void => {
          if (selectedItem) claimItem(selectedItem);
        },
        isDisabled: false,
      };
    } else if (selectedItem?.claimedBy.id === state.user?.id) {
      if (params.offList) {
        return {
          callToActionText: "Delete item",
          onAction: (): void => {
            if (selectedItem) deleteOffListItem(selectedItem);
          },
          isDisabled: false,
        };
      } else {
        return {
          callToActionText: "Unclaim",
          onAction: (): void => {
            if (selectedItem) unclaimItem(selectedItem);
          },
          isDisabled: false,
        };
      }
    } else {
      return {
        callToActionText: `Claimed by ${selectedItem.claimedBy.username}`,
        onAction: (): void => {},
        isDisabled: true,
      };
    }
  };

  const setCommentForItem = (comment: Comment): void => {
    if (!pinelist) return;
    setPinelist({
      ...pinelist,
      items: pinelist.items.map((item) => {
        if (item.id === selectedItem?.id) {
          return {
            ...item,
            comments: [...item.comments, comment],
          };
        } else {
          return item;
        }
      }),
    });
  };

  const deleteCommentForItem = (commentId: string): void => {
    if (!pinelist) return;
    setPinelist({
      ...pinelist,
      items: pinelist.items.map((item) => {
        if (item.id === selectedItem?.id) {
          return {
            ...item,
            comments: item.comments.filter((it) => it.id !== commentId),
          };
        } else {
          return item;
        }
      }),
    });
  };

  const setCommentForPinelist = (comment: Comment): void => {
    if (!pinelist) return;
    setPinelist({
      ...pinelist,
      comments: [...pinelist.comments, comment],
    });
  };

  const deleteCommentForPinelist = (commentId: string): void => {
    if (!pinelist) return;
    setPinelist({
      ...pinelist,
      comments: pinelist.comments.filter((it) => it.id !== commentId),
    });
  };

  const setNewPinelist = (pinelist: PinelistAsGiver): void => {
    setPinelist(pinelist);
  };

  const deleteOffListItem = (item: ItemAsOwner): void => {
    closeShowItemDialog();
    props.actionClient.deleteOffListItem(
      {
        item: item.id,
        pinelist: props.id,
      },
      {
        onSuccess: setNewPinelist,
        onError: (error: Error) => {
          setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
        },
      }
    );
  };

  if (showErrorPage !== "NONE") {
    return <ErrorPage type={showErrorPage} />;
  }

  if (!pinelist) {
    return <></>;
  }

  const itemsColumn = (): ReactElement => {
    return (
      <div className="col-sm-7 col-lg-8 mtl">
        <List>
          {pinelist.items.length === 0 && (
            <EmptyState
              header="No items yet"
              body={`When ${pinelist.owner.username} adds items to their list, you can view and claim them here.`}
            />
          )}
          {pinelist.items.map((item) => (
            <div key={item.id}>
              <LineItem
                textPrimary={item.title}
                textSecondary={item.details}
                item={item}
                onClick={(): void => {
                  setSelectedItem({ ...item, isOffList: false });
                }}
                commentCount={item.comments.length}
                claimedBy={item.claimedBy}
                ownedBy={undefined}
                section={item.isSection ? "uneditable-section" : "not-section"}
                actions={[]}
              />
            </div>
          ))}
          <h2 className="text-l mtxl">Off-List Items</h2>
          <p>
            Givers can add their own items to indicate to others what they are getting when it is not
            requested by the list owner. This section and all its contents are <b>not visible</b> to the list
            owner.
          </p>
          <AddItemProvider<ItemAsGiver>
            addFunction={props.actionClient.addOffListItem}
            pinelistId={props.id}
            onSuccessfulAdd={onSuccessfulAdd}
          >
            <AddItemInline addButtonType="secondary" />
            <AddItemDialog />
          </AddItemProvider>
          {pinelist.offListItems?.map((item) => (
            <div key={item.id}>
              <LineItem
                textPrimary={item.title}
                textSecondary={item.details}
                item={item}
                onClick={(): void => {
                  setSelectedItem({ ...item, isOffList: true });
                }}
                commentCount={item.comments.length}
                claimedBy={item.claimedBy}
                ownedBy={undefined}
                section={"not-section"}
                actions={[]}
              />
            </div>
          ))}
        </List>
      </div>
    );
  };

  const giversAndCommentsColumn = (): ReactElement => (
    <div className="col-sm-5 col-lg-4">
      <div className="fdr fac">{!isXS && <h2 className="text-l mrl">Givers on this pinelist</h2>}</div>
      <List>
        {pinelist.givers.map((giver) => (
          <UserListItem key={giver.id} giver={giver} editable={false} />
        ))}
      </List>

      <Divider className="mvl" />

      <CommentSection
        owner={pinelist.owner}
        pinelistId={pinelist.id}
        comments={pinelist.comments}
        actionClient={props.actionClient}
        setCommentCallback={setCommentForPinelist}
        deleteCommentCallback={deleteCommentForPinelist}
        giverView={true}
        headerSize="h2"
        itemId=""
        allowSwitchingVisibility={true}
      />
    </div>
  );

  return (
    <>
      <UserTopBar actionClient={props.actionClient} />
      <div className="container pbxxl">
        <Header
          name={pinelist.name}
          ownerId={pinelist.owner.id}
          ownerName={pinelist.owner.username}
          editable={false}
          collectionType="Pinelist"
        />

        <SwipeTabs
          tabs={[
            {
              label: "Items",
              content: <div className="row">{itemsColumn()}</div>,
            },
            {
              label: "Givers & Comments",
              content: <div className="row mtl">{giversAndCommentsColumn()}</div>,
            },
          ]}
        >
          <div className="row">
            {itemsColumn()}
            <div className="mtxl">{giversAndCommentsColumn()}</div>
          </div>
        </SwipeTabs>

        <ShowItemDialog
          selectedItem={selectedItem}
          close={closeShowItemDialog}
          callToAction={getClaimAction({ offList: selectedItem?.isOffList }).callToActionText}
          onAction={getClaimAction({ offList: selectedItem?.isOffList }).onAction}
          actionClient={props.actionClient}
          pinelist={pinelist}
          setCommentCallback={setCommentForItem}
          deleteCommentCallback={deleteCommentForItem}
          giverView={true}
          disabledAction={getClaimAction({ offList: selectedItem?.isOffList }).isDisabled}
          allowSwitchingVisibility={!selectedItem?.isOffList}
        />
      </div>
    </>
  );
};
