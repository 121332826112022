import { Button, FormControl, Snackbar, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { ChangeEvent, FormEvent, ReactElement, useContext, useEffect, useState } from "react";
import { ActionClient } from "../domain/Client";
import { Error } from "../domain/Error";
import { UserContext } from "../App";
import { ChangeUsernameError, ChangeUsernameErrorDecider } from "./ChangeUsernameErrorDecider";
import { UserActionType } from "../UserContext";
import { User } from "../domain/types";

const ChangeUsernameErrorLookup: Record<ChangeUsernameError, string> = {
  USERNAME_TOO_LONG: "Username must be 32 characters or fewer",
  USERNAME_REQUIRED: "Username is required",
  USERNAME_CONFLICT: "Username is already taken",
  GENERIC: "Sorry, something went wrong. Please try again later",
};

interface Props {
  actionClient: ActionClient;
}

export const ChangeUsername = (props: Props): ReactElement => {
  const { state, dispatch } = useContext(UserContext);
  const [newUsername, setNewUsername] = useState<string>("");
  const [errors, setErrors] = useState<ChangeUsernameError[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  useEffect(() => {
    setNewUsername(state.user?.username || "");
  }, [state.user]);

  const handleUsernameInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setNewUsername(event.target.value);
    setErrors(
      ChangeUsernameErrorDecider("USERNAME_ONCHANGE", errors, { newUsername: event.target.value }, null)
    );
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    const newErrors = ChangeUsernameErrorDecider("CLICK_UPDATE", errors, { newUsername }, null);
    setErrors(newErrors);

    if (newErrors.length > 0) {
      return;
    }

    props.actionClient.changeUsername(newUsername, {
      onSuccess: (user: User) => {
        setErrors(ChangeUsernameErrorDecider("ON_SUCCESS", errors, { newUsername }, null));
        setSnackbarOpen(true);
        dispatch({ type: UserActionType.LOGIN, user: user });
      },
      onError: (error: Error) => {
        setErrors(ChangeUsernameErrorDecider("ON_ERROR", errors, { newUsername }, error));
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <div className="pbd">
          <TextField
            fullWidth
            label="New username"
            placeholder="New username"
            name="new-username"
            variant="filled"
            value={newUsername}
            type="text"
            onChange={handleUsernameInput}
          />
        </div>
        {errors.length > 0 && (
          <div className="pvd">
            <Alert severity="error">{ChangeUsernameErrorLookup[errors[0]]}</Alert>
          </div>
        )}
        <Button color="primary" fullWidth type="submit" variant="contained">
          Update username
        </Button>
      </FormControl>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={(): void => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={(): void => setSnackbarOpen(false)} severity="success">
          Username successfully updated
        </Alert>
      </Snackbar>
    </form>
  );
};
