import { Error } from "../domain/Error";

export type ChangePasswordEvent =
  | "CURRENT_PASSWORD_ONCHANGE"
  | "NEW_PASSWORD_ONCHANGE"
  | "CLICK_UPDATE"
  | "ON_SUCCESS"
  | "ON_ERROR";
export type ChangePasswordError = "CURRENT_PASSWORD_INCORRECT" | "PASSWORDS_REQUIRED" | "GENERIC";

export type ChangePasswordFormValues = {
  currentPassword: string;
  newPassword: string;
};

const remove = (error: ChangePasswordError, errors: ChangePasswordError[]): ChangePasswordError[] => {
  return errors.filter((it) => it !== error);
};

const add = (error: ChangePasswordError, errors: ChangePasswordError[]): ChangePasswordError[] => {
  return [error, ...errors];
};

export const ChangePasswordErrorDecider = (
  event: ChangePasswordEvent,
  currentErrors: ChangePasswordError[],
  values: ChangePasswordFormValues,
  error: Error | null
): ChangePasswordError[] => {
  let newErrors = currentErrors;
  switch (event) {
    case "CURRENT_PASSWORD_ONCHANGE":
      break;
    case "NEW_PASSWORD_ONCHANGE":
      break;
    case "CLICK_UPDATE":
      newErrors = remove("GENERIC", newErrors);
      newErrors = remove("CURRENT_PASSWORD_INCORRECT", newErrors);
      if (!values.currentPassword || !values.newPassword) {
        newErrors = add("PASSWORDS_REQUIRED", newErrors);
      } else {
        newErrors = remove("PASSWORDS_REQUIRED", newErrors);
      }
      break;
    case "ON_SUCCESS":
      newErrors = [];
      break;
    case "ON_ERROR":
      if (error === Error.NOT_ALLOWED) {
        newErrors = add("CURRENT_PASSWORD_INCORRECT", newErrors);
      } else {
        newErrors = add("GENERIC", newErrors);
      }
      break;
  }

  return newErrors;
};
