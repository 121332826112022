import { ActionClient, Observer } from "../domain/Client";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Error } from "../domain/Error";
import {
  AddUserRequest,
  ClaimItemRequest,
  Comment,
  CommentRequest,
  DeleteCommentRequest,
  DeleteGiverRequest,
  DeleteItemRequest,
  DeleteMemberRequest,
  EditItemRequest,
  EditNameRequest,
  Giver,
  Group,
  GroupOverview,
  ItemAsGiver,
  ItemAsOwner,
  ItemRequest,
  MyPinelists,
  Notification,
  NotificationPreferences,
  PinelistAsGiver,
  PinelistAsOwner,
  UnauthedPinelist,
  User,
  UserProfile,
  UserUpdate,
} from "../domain/types";
import { decodeAndSetToken, salt } from "./ApiAuthClient";
import bcrypt from "bcryptjs";

type Method = "GET" | "POST" | "PUT" | "DELETE";

export const ApiActionClient = (): ActionClient => {
  const send = <T, R>(method: Method, endpoint: string, observer: Observer<R>, data?: T): void => {
    const token = getCurrentToken();
    if (!token) {
      observer.onError(Error.NOT_ALLOWED);
      return;
    }

    axios({
      method: method,
      url: endpoint,
      data: data,
      headers: { Authorization: token },
    })
      .then((response: AxiosResponse<R>) => {
        if (response.headers?.authorization) {
          decodeAndSetToken(response.headers.authorization);
        }
        observer.onSuccess(response.data);
      })
      .catch((errorResponse: AxiosError<R>) => {
        if (errorResponse.response?.status === 404) {
          return observer.onError(Error.NOT_FOUND);
        } else if (errorResponse.response?.status === 403) {
          return observer.onError(Error.NOT_ALLOWED);
        } else if (errorResponse.response?.status === 409) {
          return observer.onError(Error.DATA_ALREADY_ADDED);
        } else if (errorResponse.response?.status === 401) {
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("token");
          return observer.onError(Error.USER_SIGNED_OUT);
        }

        return observer.onError(Error.SYSTEM_ERROR);
      });
  };

  const sendNoAuth = <T, R>(method: Method, endpoint: string, observer: Observer<R>, data?: T): void => {
    axios({
      method: method,
      url: endpoint,
      data: data,
    })
      .then((response: AxiosResponse<R>) => {
        observer.onSuccess(response.data);
      })
      .catch((errorResponse: AxiosError<R>) => {
        if (errorResponse.response?.status === 404) {
          return observer.onError(Error.NOT_FOUND);
        } else if (errorResponse.response?.status === 403) {
          return observer.onError(Error.NOT_ALLOWED);
        } else if (errorResponse.response?.status === 409) {
          return observer.onError(Error.DATA_ALREADY_ADDED);
        } else if (errorResponse.response?.status === 401) {
          return observer.onError(Error.SYSTEM_ERROR);
        }

        return observer.onError(Error.SYSTEM_ERROR);
      });
  };

  const createPinelist = (name: string, observer: Observer<PinelistAsOwner>): void => {
    send("POST", "/api/pinelists", observer, { name: name });
  };

  const deletePinelist = (id: string, observer: Observer<boolean>): void => {
    send("DELETE", `/api/pinelists/${id}`, observer);
  };

  const archivePinelist = (id: string, observer: Observer<boolean>): void => {
    send("POST", `/api/pinelists/${id}/archive`, observer);
  };

  const unarchivePinelist = (id: string, observer: Observer<boolean>): void => {
    send("POST", `/api/pinelists/${id}/unarchive`, observer);
  };

  const editPinelist = (editNameRequest: EditNameRequest, observer: Observer<PinelistAsOwner>): void => {
    send("PUT", `/api/pinelists/${editNameRequest.id}`, observer, {
      name: editNameRequest.newName,
    });
  };

  const editGroup = (editNameRequest: EditNameRequest, observer: Observer<Group>): void => {
    send("PUT", `/api/groups/${editNameRequest.id}`, observer, {
      name: editNameRequest.newName,
    });
  };

  const deleteGroup = (id: string, observer: Observer<boolean>): void => {
    send("DELETE", `/api/groups/${id}`, observer);
  };

  const searchUsernames = (usernameFragment: string, observer: Observer<Giver[]>): void => {
    send(
      "POST",
      "/api/users/search",
      {
        onSuccess: (users: User[]) => {
          observer.onSuccess(
            users.map(
              (it): Giver => ({
                id: it.id,
                giverType: "user",
                displayName: it.username,
              })
            )
          );
        },
        onError: observer.onError,
      },
      { usernameFragment }
    );
  };

  const searchUsernamesOrGroups = (fragment: string, observer: Observer<Giver[]>): void => {
    const userSearchPromise: Promise<User[]> = new Promise((resolve, reject) => {
      send(
        "POST",
        "/api/users/search",
        {
          onSuccess: (users: User[]) => {
            resolve(users);
          },
          onError: (e) => reject(e),
        },
        { usernameFragment: fragment }
      );
    });

    const groupSearchPromise: Promise<Group[]> = new Promise((resolve, reject) => {
      send(
        "POST",
        "/api/groups/search",
        {
          onSuccess: (groups: Group[]) => {
            resolve(groups);
          },
          onError: (e) => reject(e),
        },
        { groupNameFragment: fragment }
      );
    });

    Promise.all([userSearchPromise, groupSearchPromise])
      .then(([users, groups]) => {
        observer.onSuccess([
          ...groups.map(
            (it): Giver => ({
              id: it.id,
              giverType: "group",
              displayName: it.name,
            })
          ),
          ...users.map(
            (it): Giver => ({
              id: it.id,
              giverType: "user",
              displayName: it.username,
            })
          ),
        ]);
      })
      .catch((e) => {
        observer.onError(e);
      });
  };

  const getMyPinelists = (observer: Observer<MyPinelists>): void => {
    send("GET", "/api/pinelists", observer);
  };

  const getPinelistByIdAsOwner = (id: string, observer: Observer<PinelistAsOwner>): void => {
    send("GET", `/api/pinelists/${id}/as/owner`, observer);
  };

  const getPinelistByIdAsGiver = (id: string, observer: Observer<PinelistAsGiver>): void => {
    send("GET", `/api/pinelists/${id}/as/giver`, observer);
  };

  const addItem = (itemRequest: ItemRequest, observer: Observer<ItemAsOwner>): void => {
    send("POST", `/api/pinelists/${itemRequest.pinelist}/items`, observer, {
      title: itemRequest.title,
      details: itemRequest.details,
      isSection: itemRequest.isSection,
    });
  };

  const addOffListItem = (itemRequest: ItemRequest, observer: Observer<ItemAsGiver>): void => {
    send("POST", `/api/pinelists/${itemRequest.pinelist}/off-list-items`, observer, {
      title: itemRequest.title,
      details: itemRequest.details,
    });
  };

  const addGiver = (addUserRequest: AddUserRequest, observer: Observer<Giver>): void => {
    send("POST", `/api/pinelists/${addUserRequest.collectionId}/givers`, observer, {
      id: addUserRequest.userId,
      giverType: addUserRequest.giverType,
      invite: addUserRequest.invite,
    });
  };

  const claimItem = (claimItemRequest: ClaimItemRequest, observer: Observer<PinelistAsGiver>): void => {
    send("POST", `/api/pinelists/${claimItemRequest.pinelist}/claim`, observer, {
      item: claimItemRequest.item,
    });
  };

  const unclaimItem = (claimItemRequest: ClaimItemRequest, observer: Observer<PinelistAsGiver>): void => {
    send("POST", `/api/pinelists/${claimItemRequest.pinelist}/unclaim`, observer, {
      item: claimItemRequest.item,
    });
  };

  const editItem = (editItemRequest: EditItemRequest, observer: Observer<ItemAsOwner>): void => {
    send("POST", `/api/pinelists/${editItemRequest.pinelist}/items/${editItemRequest.item}`, observer, {
      title: editItemRequest.newTitle,
      details: editItemRequest.newDetails,
      isSection: editItemRequest.isSection,
    });
  };

  const deleteItem = (deleteItemRequest: DeleteItemRequest, observer: Observer<PinelistAsOwner>): void => {
    send("DELETE", `/api/pinelists/${deleteItemRequest.pinelist}/items/${deleteItemRequest.item}`, observer);
  };

  const deleteOffListItem = (
    deleteItemRequest: DeleteItemRequest,
    observer: Observer<PinelistAsGiver>
  ): void => {
    send(
      "DELETE",
      `/api/pinelists/${deleteItemRequest.pinelist}/off-list-items/${deleteItemRequest.item}`,
      observer
    );
  };

  const deleteGiver = (deleteGiverRequest: DeleteGiverRequest, observer: Observer<PinelistAsOwner>): void => {
    send(
      "DELETE",
      `/api/pinelists/${deleteGiverRequest.pinelist}/givers/${deleteGiverRequest.giver}`,
      observer
    );
  };

  const deleteComment = (deleteCommentRequest: DeleteCommentRequest, observer: Observer<boolean>): void => {
    send(
      "DELETE",
      `/api/pinelists/${deleteCommentRequest.pinelistId}/comments/${deleteCommentRequest.commentId}`,
      observer
    );
  };

  const getMyGroups = (observer: Observer<GroupOverview[]>): void => {
    send("GET", "/api/groups", observer);
  };

  const createGroup = (name: string, observer: Observer<Group>): void => {
    send("POST", "/api/groups", observer, { name: name });
  };

  const addMember = (addUserRequest: AddUserRequest, observer: Observer<Giver>): void => {
    send("POST", `/api/groups/${addUserRequest.collectionId}/members`, observer, {
      memberId: addUserRequest.userId,
    });
  };

  const deleteMember = (deleteMemberRequest: DeleteMemberRequest, observer: Observer<Group>): void => {
    send(
      "DELETE",
      `/api/groups/${deleteMemberRequest.groupId}/members/${deleteMemberRequest.memberId}`,
      observer
    );
  };

  const getGroupById = (id: string, observer: Observer<Group>): void => {
    send("GET", `/api/groups/${id}`, observer);
  };

  const changePassword = (currentPassword: string, newPassword: string, observer: Observer<void>): void => {
    send("POST", `/api/auth-management/change-password`, observer, {
      currentPassword: bcrypt.hashSync(currentPassword, salt),
      newPassword: bcrypt.hashSync(newPassword, salt),
    });
  };

  const resetPasswordWithCode = (code: string, newPassword: string, observer: Observer<void>): void => {
    send("POST", `/api/auth-management/reset-password`, observer, {
      currentPassword: code,
      newPassword: bcrypt.hashSync(newPassword, salt),
    });
  };

  const updateUser = (userUpdate: UserUpdate, observer: Observer<User>): void => {
    send("PUT", `/api/users`, observer, userUpdate);
  };

  const createInviteLink = (id: string, observer: Observer<PinelistAsOwner>): void => {
    send("POST", `/api/pinelists/${id}/invite`, observer);
  };

  const revokeInviteLink = (id: string, observer: Observer<PinelistAsOwner>): void => {
    send("DELETE", `/api/pinelists/${id}/invite`, observer);
  };

  const getPinelistByInvite = (inviteId: string, observer: Observer<UnauthedPinelist>): void => {
    sendNoAuth("GET", `/api/pinelists/by/invite/${inviteId}`, observer);
  };

  const addComment = (commentRequest: CommentRequest, observer: Observer<Comment>): void => {
    send("POST", `/api/pinelists/${commentRequest.pinelist}/comments`, observer, {
      item: commentRequest.item,
      text: commentRequest.text,
      visibility: commentRequest.visibility,
    });
  };

  const getNotifications = (observer: Observer<Notification[]>): void => {
    send("GET", `/api/users/notifications`, observer);
  };

  const getUserProfile = (userId: string, observer: Observer<UserProfile>): void => {
    send("GET", `/api/users/${userId}/profile`, observer);
  };

  const markAsRead = (notifications: string[], observer: Observer<Notification[]>): void => {
    send("PUT", `/api/users/notifications`, observer, notifications);
  };

  const reorderItems = (id: string, items: string[], observer: Observer<ItemAsOwner[]>): void => {
    send("POST", `/api/pinelists/${id}/items-reorder`, observer, items);
  };

  const changeUsername = (newUsername: string, observer: Observer<User>): void => {
    send("POST", `/api/auth-management/change-username`, observer, {
      username: newUsername,
    });
  };

  const getPreferences = (observer: Observer<NotificationPreferences>): void => {
    send("GET", `/api/users/preferences`, observer);
  };

  const savePreferences = (
    preferences: NotificationPreferences,
    observer: Observer<NotificationPreferences>
  ): void => {
    send("PUT", `/api/users/preferences`, observer, preferences);
  };

  const getCurrentToken = (): string => {
    const token = window.localStorage.getItem("token");
    return token ? token : "";
  };

  return {
    createPinelist,
    deletePinelist,
    editPinelist,
    getMyPinelists,
    getPinelistByIdAsOwner,
    getPinelistByIdAsGiver,
    addItem,
    addOffListItem,
    searchUsernames,
    searchUsernamesOrGroups,
    addGiver,
    claimItem,
    unclaimItem,
    editItem,
    deleteItem,
    deleteOffListItem,
    deleteGiver,
    createGroup,
    getMyGroups,
    getGroupById,
    changePassword,
    updateUser,
    createInviteLink,
    revokeInviteLink,
    getPinelistByInvite,
    addComment,
    resetPasswordWithCode,
    getNotifications,
    markAsRead,
    reorderItems,
    changeUsername,
    getUserProfile,
    archivePinelist,
    unarchivePinelist,
    deleteComment,
    addMember,
    deleteMember,
    editGroup,
    getPreferences,
    savePreferences,
    deleteGroup,
  };
};
