import React, { ReactElement, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { ActionClient } from "../../domain/Client";
import { Comment, Giver, ItemAsOwner, PinelistAsOwner } from "../../domain/types";
import { ErrorPage, ErrorPageType } from "../../ErrorPage";
import { Header } from "../Header";
import { Button, Divider, List } from "@material-ui/core";
import { UserTopBar } from "../../components/UserTopBar";
import { AddUser } from "./AddUser";
import { UserListItem } from "./UserListItem";
import { SwipeTabs } from "../../components/SwipeTabs";
import { Error } from "../../domain/Error";
import { EditItemProvider } from "./EditItemContext";
import { AddItemProvider } from "./AddItemContext";
import { ItemsColumn } from "./ItemsColumn";
import { InviteLinkDialog } from "./InviteLinkDialog";
import { CommentSection } from "../comments/CommentSection";
import { EmptyState } from "../../components/EmptyState";
import { useDocTitle } from "../../hooks/useDocTitle";

interface Props extends RouteComponentProps {
  actionClient: ActionClient;
  id: string;
}

export const PinelistOwnerPage = (props: Props): ReactElement => {
  const [pinelist, setPinelist] = useState<PinelistAsOwner | undefined>(undefined);
  const [items, setItems] = useState<ItemAsOwner[]>([]);
  const [showErrorPage, setShowErrorPage] = useState<ErrorPageType | "NONE">("NONE");
  const [inviteLinkDialogIsOpen, setInviteLinkDialogIsOpen] = useState<boolean>(false);
  const [inviteLink, setInviteLink] = useState<string>("");
  useDocTitle(pinelist?.name);

  const setNewPinelist = (pinelist: PinelistAsOwner): void => {
    setPinelist(pinelist);
    setItems(pinelist.items);
  };

  useEffect(() => {
    props.actionClient.getPinelistByIdAsOwner(props.id, {
      onSuccess: setNewPinelist,
      onError: (error: Error) => {
        setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  }, [props.id, props.actionClient]);

  const reorderItems = (newOrderedItems: ItemAsOwner[]): void => {
    setItems(newOrderedItems);
    props.actionClient.reorderItems(
      props.id,
      newOrderedItems.map((it) => it.id),
      {
        onSuccess: setItems,
        onError: () => {},
      }
    );
  };

  const closeInviteLinkDialog = (): void => {
    setInviteLinkDialogIsOpen(false);
  };

  const deleteItem = (item: ItemAsOwner): void => {
    props.actionClient.deleteItem(
      {
        item: item.id,
        pinelist: props.id,
      },
      {
        onSuccess: setNewPinelist,
        onError: (error: Error) => {
          setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
        },
      }
    );
  };

  const deleteGiver = (giverId: string): void => {
    props.actionClient.deleteGiver(
      {
        giver: giverId,
        pinelist: props.id,
      },
      {
        onSuccess: setNewPinelist,
        onError: (error: Error) => {
          setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
        },
      }
    );
  };

  const onAddedGiver = (giver: Giver): void => {
    if (pinelist) {
      setPinelist({
        ...pinelist,
        givers: [...pinelist.givers, giver],
      });
    }
  };

  const onSuccessfulEdit = (newItem: ItemAsOwner): void => {
    const indexOfEditingItem = items.findIndex((it) => it.id === newItem.id);
    setItems(Object.assign([], items, { [indexOfEditingItem]: newItem }));
  };

  const onSuccessfulAdd = (newItem: ItemAsOwner): void => {
    setItems([newItem, ...items]);
  };

  const onInviteLink = (): void => {
    if (!pinelist?.invite) {
      props.actionClient.createInviteLink(props.id, {
        onSuccess: (pinelist: PinelistAsOwner) => {
          setNewPinelist(pinelist);
          setInviteLinkDialogIsOpen(true);
          setInviteLink(`${window.location.origin}/invite/${pinelist.invite}`);
        },
        onError: (error: Error) => {
          setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
        },
      });
    } else {
      setInviteLinkDialogIsOpen(true);
      setInviteLink(`${window.location.origin}/invite/${pinelist.invite}`);
    }
  };

  const onRevokeInviteLink = (): void => {
    props.actionClient.revokeInviteLink(props.id, {
      onSuccess: (pinelist: PinelistAsOwner) => {
        setInviteLinkDialogIsOpen(false);
        setNewPinelist(pinelist);
      },
      onError: (error: Error) => {
        setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  };

  const setCommentForItem = (comment: Comment, id: string): void => {
    setItems(
      items.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            comments: [...item.comments, comment],
          };
        } else {
          return item;
        }
      })
    );
  };

  const deleteCommentForItem = (commentId: string, id: string): void => {
    setItems(
      items.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            comments: item.comments.filter((it) => it.id !== commentId),
          };
        } else {
          return item;
        }
      })
    );
  };

  const setCommentForPinelist = (comment: Comment): void => {
    if (!pinelist) return;
    setPinelist({
      ...pinelist,
      comments: [...pinelist.comments, comment],
    });
  };

  const deleteCommentForPinelist = (commentId: string): void => {
    if (!pinelist) return;
    setPinelist({
      ...pinelist,
      comments: pinelist.comments.filter((it) => it.id !== commentId),
    });
  };

  if (showErrorPage !== "NONE") {
    return <ErrorPage type={showErrorPage} />;
  }

  if (!pinelist) {
    return <></>;
  }

  const itemsColumn = (): ReactElement => (
    <EditItemProvider
      actionClient={props.actionClient}
      pinelistId={props.id}
      onSuccessfulEdit={onSuccessfulEdit}
    >
      <AddItemProvider<ItemAsOwner>
        addFunction={props.actionClient.addItem}
        pinelistId={props.id}
        onSuccessfulAdd={onSuccessfulAdd}
      >
        <ItemsColumn
          pinelist={pinelist}
          actionClient={props.actionClient}
          items={items}
          deleteItem={deleteItem}
          reorderItems={reorderItems}
          setCommentForItemCallback={setCommentForItem}
          deleteCommentForItemCallback={deleteCommentForItem}
          location={props.location}
        />
      </AddItemProvider>
    </EditItemProvider>
  );

  const giversAndCommentsColumn = (): ReactElement => (
    <div className="col-sm-5 col-lg-4">
      <Button variant="outlined" color="primary" onClick={onInviteLink}>
        {pinelist.invite ? "Show giver invite link" : "Create giver invite link"}
      </Button>
      <div className="fdr fac mtl">
        <h2 className="text-l mrl">Givers on this pinelist</h2>
        <AddUser
          id={pinelist.id}
          onAdded={onAddedGiver}
          addUserFunction={props.actionClient.addGiver}
          actionClient={props.actionClient}
          userType="giver"
        />
      </div>
      <List>
        {pinelist.givers.length === 0 && (
          <EmptyState
            header="No givers yet"
            body="Add a giver to your list to allow others to see and claim items."
          />
        )}
        {pinelist.givers.map((giver) => (
          <UserListItem key={giver.id} giver={giver} editable={true} onDelete={deleteGiver} />
        ))}
      </List>

      <Divider className="mvl" />

      <CommentSection
        owner={pinelist.owner}
        pinelistId={pinelist.id}
        comments={pinelist.comments}
        actionClient={props.actionClient}
        setCommentCallback={setCommentForPinelist}
        deleteCommentCallback={deleteCommentForPinelist}
        giverView={false}
        headerSize="h2"
        itemId=""
        allowSwitchingVisibility={false}
      />
    </div>
  );

  return (
    <>
      <UserTopBar actionClient={props.actionClient} />

      <div className="container pbxxl">
        <Header
          name={pinelist.name}
          ownerId={pinelist.owner.id}
          ownerName={pinelist.owner.username}
          editable={true}
          id={props.id}
          collectionType="Pinelist"
          setNewCollection={setNewPinelist}
          editNameFunction={props.actionClient.editPinelist}
        />

        <SwipeTabs
          tabs={[
            {
              label: "Items",
              content: <div className="row">{itemsColumn()}</div>,
            },
            {
              label: "Givers & Comments",
              content: <div className="row mtl">{giversAndCommentsColumn()}</div>,
            },
          ]}
        >
          <div className="row">
            {itemsColumn()}
            <div className="mtl">{giversAndCommentsColumn()}</div>
          </div>
        </SwipeTabs>

        <InviteLinkDialog
          isOpen={inviteLinkDialogIsOpen}
          close={closeInviteLinkDialog}
          onRevoke={onRevokeInviteLink}
          inviteLink={inviteLink}
        />
      </div>
    </>
  );
};
