import React, { ChangeEvent, ReactElement, useState } from "react";
import { Button, CircularProgress, Icon, TextField } from "@material-ui/core";
import { PopupDialog } from "../../components/PopupDialog";
import { Alert, Autocomplete, AutocompleteChangeReason, createFilterOptions } from "@material-ui/lab";
import { AddUserRequest, Giver } from "../../domain/types";
import { ActionClient, Observer } from "../../domain/Client";
import { AddUserError, AddUserErrorDecider } from "./AddUserErrorDecider";
import { Error } from "../../domain/Error";

interface Props {
  id: string;
  actionClient: ActionClient;
  onAdded: (giver: Giver) => void;
  addUserFunction: (addUserRequest: AddUserRequest, observer: Observer<Giver>) => void;
  userType: "giver" | "member";
}

export const AddUser = (props: Props): ReactElement => {
  const SEARCH_CHARS = 1;
  const [addUserDialogIsOpen, setAddUserDialogIsOpen] = useState<boolean>(false);
  const [searchUsernamesLoading, setSearchUsernamesLoading] = useState<boolean>(false);
  const [searchedGivers, setSearchedGivers] = useState<Giver[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [errors, setErrors] = useState<AddUserError[]>([]);

  const collectionType = ((): string => {
    switch (props.userType) {
      case "giver":
        return "pinelist";
      case "member":
        return "group";
    }
  })();

  const dialogBody = ((): string => {
    switch (props.userType) {
      case "giver":
        return `Start typing the username of the ${props.userType} you would like to add to this ${collectionType}.\n\nYou can also search for a Group you are in, to make this list visible to all members of that group.`;
      case "member":
        return `Start typing the username of the ${props.userType} you would like to add to this ${collectionType}`;
    }
  })();

  const inputLabel = ((): string => {
    switch (props.userType) {
      case "giver":
        return "Search for username or group name";
      case "member":
        return "Search for username";
    }
  })();

  const AddUserErrorLookup: Record<AddUserError, string> = {
    GENERIC: "Sorry, something went wrong. Please try again later",
    USER_CONFLICT: `This user is already a ${props.userType} on this ${collectionType}`,
  };

  const openAddUserDialog = (): void => {
    setAddUserDialogIsOpen(true);
  };

  const closeAddUserDialog = (): void => {
    setErrors(AddUserErrorDecider("ON_CLOSE", errors, null));
    setAddUserDialogIsOpen(false);
  };

  const handleTypeaheadChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setSearchValue(value);
    if (value.length >= SEARCH_CHARS) {
      setSearchUsernamesLoading(true);

      const searchFunction = ((): ((usernameFragment: string, observer: Observer<Giver[]>) => void) => {
        switch (props.userType) {
          case "giver":
            return props.actionClient.searchUsernamesOrGroups;
          case "member":
            return props.actionClient.searchUsernames;
        }
      })();

      searchFunction(value, {
        onSuccess: (givers: Giver[]) => {
          setErrors(AddUserErrorDecider("ON_SUCCESS", errors, null));
          setSearchUsernamesLoading(false);
          setSearchedGivers(givers);
        },
        onError: (error: Error) => {
          setSearchUsernamesLoading(false);
          setErrors(AddUserErrorDecider("ON_ERROR", errors, error));
        },
      });
    }
  };

  const handleTypeaheadSelection = (
    event: ChangeEvent<unknown>,
    value: Giver | null,
    reason: AutocompleteChangeReason
  ): void => {
    if (value && reason === "select-option") {
      props.addUserFunction(
        {
          userId: value.id,
          collectionId: props.id,
          giverType: value.giverType,
        },
        {
          onSuccess: (giver: Giver) => {
            props.onAdded(giver);
            closeAddUserDialog();
          },
          onError: (error: Error) => {
            setErrors(AddUserErrorDecider("ON_ERROR", errors, error));
          },
        }
      );
    }
  };

  return (
    <>
      <Button onClick={openAddUserDialog} color="primary" variant="outlined">
        <Icon aria-label={`Add ${props.userType}`}>person_add</Icon>
      </Button>

      <PopupDialog
        isOpen={addUserDialogIsOpen}
        close={closeAddUserDialog}
        title={`Add ${props.userType}`}
        description={dialogBody}
        callToActionText="Add"
        onAction={(): void => {}}
      >
        <>
          <Autocomplete
            options={searchValue.length >= SEARCH_CHARS ? searchedGivers : []}
            getOptionLabel={(giver: Giver): string => giver.displayName}
            onChange={handleTypeaheadSelection}
            loading={searchUsernamesLoading}
            filterOptions={createFilterOptions({
              matchFrom: "start",
              limit: 15,
            })}
            renderOption={(option): ReactElement => (
              <div data-value={option.displayName} className="fdr fac">
                {option.giverType === "group" && <Icon className="mrs">groups</Icon>}
                {option.displayName}
              </div>
            )}
            renderInput={(params): ReactElement => (
              <TextField
                {...params}
                label={inputLabel}
                variant="filled"
                margin="dense"
                id="user-username"
                name="user-username"
                fullWidth
                onChange={handleTypeaheadChange}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {searchUsernamesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          {errors.length > 0 && (
            <div className="pvd">
              <Alert severity="error">{AddUserErrorLookup[errors[0]]}</Alert>
            </div>
          )}
        </>
      </PopupDialog>
    </>
  );
};
