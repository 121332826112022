import React, { ReactElement } from "react";
import {
  Icon,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { Giver } from "../../domain/types";
import { MediaQueries } from "../../components/MediaQueries";
import { Username } from "../../components/Username";

interface Props {
  giver: Giver;
  editable: boolean;
  onDelete?: (userId: string) => void;
}

export const UserListItem = (props: Props): ReactElement => {
  const isTouchscreen = useMediaQuery(MediaQueries.isTouchscreen);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getUserUrl = (): string => {
    const urlPrefix = ((): string => {
      switch (props.giver.giverType) {
        case "group":
          return "groups";
        case "user":
          return "profile";
      }
    })();
    return `/${urlPrefix}/${props.giver.id}`;
  };

  return (
    <div className="onhover-parent">
      <a href={getUserUrl()} className="button-styled-href" data-testid="button-link">
        <ListItem button>
          <ListItemText
            primary={
              <Username username={props.giver.displayName} isGroup={props.giver.giverType === "group"} />
            }
          />
          {props.editable && props.onDelete && (
            <ListItemSecondaryAction>
              {!isTouchscreen && (
                <div className="onhover-child">
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(): void => (props.onDelete ? props.onDelete(props.giver.id) : undefined)}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              )}
              {isTouchscreen && (
                <>
                  <IconButton edge="end" aria-label="more_vert" onClick={handleClick}>
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem
                      aria-label="delete"
                      onClick={(): void => (props.onDelete ? props.onDelete(props.giver.id) : undefined)}
                    >
                      <Icon>delete</Icon>
                      <span className="mhd all-caps">Delete</span>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </a>
    </div>
  );
};
