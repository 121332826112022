import React, { ReactElement, useState } from "react";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import { LandingTopBar } from "../components/LandingTopBar";
import { SwipeTabs } from "../components/SwipeTabs";
import { RegisterForm } from "../signing-in/RegisterForm";
import { LoginForm } from "../signing-in/LoginForm";
import { ActionClient, AuthClient } from "../domain/Client";
import { useMountEffect } from "../helpers";
import { ErrorPage } from "../ErrorPage";
import { UnauthedPinelist, User } from "../domain/types";
import { Error } from "../domain/Error";
import { useDocTitle } from "../hooks/useDocTitle";

interface Props extends RouteComponentProps {
  authClient: AuthClient;
  actionClient: ActionClient;
  inviteId: string;
}

enum InviteError {
  NOT_FOUND,
  SYSTEM_ERROR,
}

export const InvitePage = (props: Props): ReactElement => {
  const [error, setError] = useState<InviteError | undefined>(undefined);
  const [pinelist, setPinelist] = useState<UnauthedPinelist | undefined>(undefined);
  useDocTitle(undefined);

  useMountEffect(() => {
    props.actionClient.getPinelistByInvite(props.inviteId, {
      onSuccess: (pinelist: UnauthedPinelist) => {
        setPinelist(pinelist);
      },
      onError: (error: Error) => {
        error === Error.NOT_FOUND ? setError(InviteError.NOT_FOUND) : setError(InviteError.SYSTEM_ERROR);
      },
    });
  });

  const onSignIn = async (user: User): Promise<void> => {
    if (!pinelist) return;

    props.actionClient.addGiver(
      {
        userId: user.id,
        collectionId: pinelist?.id,
        invite: props.inviteId,
        giverType: "user",
      },
      {
        onSuccess: () => {
          navigate(`/pinelists/${pinelist?.id}/as/giver`);
        },
        onError: () => {
          setError(InviteError.SYSTEM_ERROR);
        },
      }
    );
  };

  if (error === InviteError.NOT_FOUND) {
    return <InviteNotFoundPage />;
  } else if (error) {
    return <ErrorPage />;
  }

  if (!pinelist) {
    return <></>;
  }

  return (
    <>
      <LandingTopBar />
      <div className="container">
        <div className="row">
          <div className="col-sm-offset-2 col-sm-8 mtd mbm">
            <h1 className="text-xxl mtl">You've been invited!</h1>
            <p className="text-m">
              Pinelist is a gift-list app for creating wishlists and claiming items among gift-givers.
            </p>
            <p className="text-m">
              <span className="bold-caps">{pinelist.ownerUsername}</span> invited you to join their list:{" "}
              <span className="bold-caps">{pinelist.name}</span>
            </p>
            <p className="text-m">Sign up or log in below to view their list and get started.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-3 col-sm-6">
            <SwipeTabs
              includeDesktop={true}
              tabs={[
                {
                  label: "Sign up",
                  content: (
                    <div className="mtm" data-testid="register-form">
                      <RegisterForm authClient={props.authClient} onRegister={onSignIn} />
                    </div>
                  ),
                },
                {
                  label: "Log in",
                  content: (
                    <div className="mtm" data-testid="login-form">
                      <LoginForm authClient={props.authClient} onLogin={onSignIn} />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const InviteNotFoundPage = (): ReactElement => {
  return (
    <>
      <LandingTopBar />
      <div className="container mtl">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <h1 className="text-xxl">Sorry, this invite link is invalid or expired</h1>
            <p>
              Visit the <Link to="/">homepage</Link> to learn more about Pinelist.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
