// timestamp in format 2021-10-31T21:49:17.586Z
import { currentTimeInMillis } from "./currentTimeInMillis";
import dayjs from "dayjs";

export const getRelativeTime = (timestamp: string): string => {
  if (!timestamp) return "";

  const date = new Date(timestamp).getTime();

  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "always" });
  const oneMinuteInMs = 1000 * 60;
  const oneHourInMs = oneMinuteInMs * 60;
  const oneDayInMs = oneHourInMs * 24;
  const millisDifference = date - currentTimeInMillis();

  const sevenDaysInMs = oneDayInMs * 7;
  const absoluteDifference = Math.abs(millisDifference);

  if (absoluteDifference >= sevenDaysInMs) {
    return dayjs(timestamp).format("MMM D YYYY");
  }

  if (absoluteDifference < sevenDaysInMs && absoluteDifference >= oneDayInMs) {
    const daysDifference = Math.round((date - currentTimeInMillis()) / oneDayInMs);
    return rtf.format(daysDifference, "day");
  }

  if (absoluteDifference < oneDayInMs && absoluteDifference >= oneHourInMs) {
    const hoursDifference = Math.round((date - currentTimeInMillis()) / oneHourInMs);
    return rtf.format(hoursDifference, "hour");
  }

  if (absoluteDifference < oneHourInMs) {
    const minutesDifference = Math.round((date - currentTimeInMillis()) / oneMinuteInMs);
    return rtf.format(minutesDifference, "minute");
  }

  return dayjs(timestamp).format("MMM D YYYY");
};
