import React, { ReactElement, useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import { ChangePassword } from "./ChangePassword";
import { ActionClient } from "../domain/Client";
import { UpdateEmail } from "./UpdateEmail";
import { UserContext } from "../App";
import { ChangeUsername } from "./ChangeUsername";
import { useDocTitle } from "../hooks/useDocTitle";
import { LeftNavPage } from "../components/LeftNavPage";
import { NotificationPreferencesSection } from "./NotificationPreferencesSection";

interface Props extends RouteComponentProps {
  actionClient: ActionClient;
}

export const AccountSettingsPage = (props: Props): ReactElement => {
  const { state } = useContext(UserContext);
  useDocTitle("Account Settings");

  const getEmailHeader = (): string => {
    if (state.user?.email) {
      return "Update your email";
    }

    return "Provide your email";
  };

  return (
    <LeftNavPage
      pageTitle="Account Settings"
      actionClient={props.actionClient}
      initialId={"CHANGE_USERNAME"}
      navItems={{
        CHANGE_USERNAME: {
          name: "Change your username",
          component: <ChangeUsername actionClient={props.actionClient} />,
        },
        CHANGE_PASSWORD: {
          name: "Change your password",
          component: <ChangePassword actionClient={props.actionClient} />,
        },
        EMAIL: { name: getEmailHeader(), component: <UpdateEmail actionClient={props.actionClient} /> },
        NOTIFICATION_PREFERENCES: {
          name: "Notification preferences",
          component: <NotificationPreferencesSection actionClient={props.actionClient} />,
        },
      }}
    />
  );
};
