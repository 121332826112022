/* eslint-disable jsx-a11y/no-autofocus */

import React, { ReactElement } from "react";
import { ifEnterKey } from "../helpers";
import { TextField, useMediaQuery } from "@material-ui/core";
import { MediaQueries } from "../components/MediaQueries";

interface Props {
  label: string;
  onChange: (value: string) => void;
  submit: () => void;
  defaultValue: string;
  textSize: "default" | "text-l" | "text-xxl";
  errorText: string;
}

export const InlineEditField = (props: Props): ReactElement => {
  const isDesktopAndUp = useMediaQuery(MediaQueries.desktopAndUp);

  const style = ((): { fontWeight: number; fontSize: string } => {
    switch (props.textSize) {
      case "default":
        return {
          fontWeight: 400,
          fontSize: "1rem",
        };
      case "text-l":
        return {
          fontWeight: 500,
          fontSize: isDesktopAndUp ? "1.375rem" : "1.1rem",
        };
      case "text-xxl":
        return {
          fontWeight: 700,
          fontSize: isDesktopAndUp ? "5rem" : "3rem",
        };
    }
  })();

  return (
    <TextField
      autoFocus={true}
      id={props.label.split(" ").join("-")}
      name={props.label.split(" ").join("-")}
      type="text"
      variant="filled"
      fullWidth
      style={{ marginBottom: 0 }}
      error={!!props.errorText}
      helperText={props.errorText}
      onChange={(event): void => props.onChange(event.target.value)}
      onKeyDown={(e): void => ifEnterKey(e, props.submit)}
      placeholder=""
      onBlur={props.submit}
      defaultValue={props.defaultValue}
      multiline={props.textSize === "default"}
      InputProps={{
        style: {
          paddingTop: props.textSize === "default" ? "1rem" : 0,
          lineHeight: 1.1,
          paddingLeft: 0,
        },
      }}
      inputProps={{
        "aria-label": props.label,
        style: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: "6px",
          lineHeight: 1.1,
          ...style,
        },
      }}
    />
  );
};
