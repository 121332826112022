import { Button, FormControl, Snackbar, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { ChangeEvent, FormEvent, ReactElement, useContext, useState } from "react";
import { ActionClient } from "../domain/Client";
import { Error } from "../domain/Error";
import { UpdateEmailError, UpdateEmailErrorDecider } from "./UpdateEmailErrorDecider";
import { UserContext } from "../App";
import { User } from "../domain/types";
import { UserActionType } from "../UserContext";

const UpdateEmailErrorLookup: Record<UpdateEmailError, string> = {
  GENERIC: "Sorry, something went wrong. Please try again later",
};

interface Props {
  actionClient: ActionClient;
}

export const UpdateEmail = (props: Props): ReactElement => {
  const { state, dispatch } = useContext(UserContext);
  const [email, setEmail] = useState<string>(state.user?.email || "");
  const [errors, setErrors] = useState<UpdateEmailError[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    const newErrors = UpdateEmailErrorDecider("CLICK_UPDATE", errors, email, null);
    setErrors(newErrors);

    if (newErrors.length > 0) {
      return;
    }

    props.actionClient.updateUser(
      { email },
      {
        onSuccess: (newUser: User) => {
          setErrors(UpdateEmailErrorDecider("ON_SUCCESS", errors, email, null));
          setSnackbarOpen(true);
          dispatch({
            type: UserActionType.UPDATE,
            user: newUser,
          });
        },
        onError: (error: Error) => {
          setErrors(UpdateEmailErrorDecider("ON_ERROR", errors, email, error));
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      {!state.user?.email && (
        <p>Provide your email to be able to retrieve your account if username or password is forgotten</p>
      )}
      <FormControl fullWidth>
        <div className="pbd">
          <TextField
            fullWidth
            label="Email"
            placeholder="Email"
            name="email"
            variant="filled"
            value={email}
            type="text"
            onChange={handleInput}
          />
        </div>
        {errors.length > 0 && (
          <div className="pvd">
            <Alert severity="error">{UpdateEmailErrorLookup[errors[0]]}</Alert>
          </div>
        )}
        <Button color="primary" fullWidth type="submit" variant="contained">
          Update email
        </Button>
      </FormControl>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={(): void => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={(): void => setSnackbarOpen(false)} severity="success">
          Email successfully updated
        </Alert>
      </Snackbar>
    </form>
  );
};
