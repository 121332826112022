import React, { ReactElement } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { formatDescription } from "../helpers";

interface Props {
  isOpen: boolean;
  close: () => void;
  title?: string;
  description: string;
  callToActionText: string;
  onAction: () => void;
  children: ReactElement;
  disabledAction?: boolean;
  hideSecondaryAction?: boolean;
  hidePrimaryAction?: boolean;
  secondaryActionText?: string;
  onSecondaryAction?: () => void;
}

export const PopupDialog = (props: Props): ReactElement => {
  const disabledAction = props.disabledAction ?? false;

  return (
    <Dialog fullWidth open={props.isOpen} onClose={props.close} aria-labelledby="form-dialog-title">
      {props.title && <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>}
      <DialogContent>
        {formatDescription(props.description)}
        {props.children}
      </DialogContent>
      <DialogActions>
        {!props.hideSecondaryAction && (
          <Button
            onClick={props.onSecondaryAction || props.close}
            color="primary"
            data-testid={`dialog-secondary-${props.secondaryActionText || "Cancel"}`}
          >
            {props.secondaryActionText || "Cancel"}
          </Button>
        )}
        {!props.hidePrimaryAction && (
          <Button
            id={props.callToActionText.replace(/ /g, "")}
            onClick={props.onAction}
            variant="contained"
            color="primary"
            disabled={disabledAction}
            data-testid={`dialog-cta-${props.callToActionText}`}
          >
            {props.callToActionText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
