import React, { ReactElement, useState } from "react";
import { TextField, Snackbar } from "@material-ui/core";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import { AuthClient } from "../domain/Client";
import { UserContext } from "../App";
import { useMountEffect } from "../helpers";
import { LandingTopBar } from "../components/LandingTopBar";
import { LoginForm } from "./LoginForm";
import { Alert } from "@material-ui/lab";
import { PopupDialog } from "../components/PopupDialog";
import { useDocTitle } from "../hooks/useDocTitle";
import { BackHomeButton } from "../components/BackHomeButton";

interface Props extends RouteComponentProps {
  authClient: AuthClient;
}

export const Login = (props: Props): ReactElement => {
  const { state } = React.useContext(UserContext);
  const [resetDialogIsOpen, setResetDialogIsOpen] = useState<boolean>(false);
  const [resetPasswordUsername, setResetPasswordUsername] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  useDocTitle(undefined);

  useMountEffect(() => {
    if (state.isAuth) {
      navigate("/home");
    }
  });

  const requestPasswordReset = (): void => {
    const resetStateAndShowSnackbar = (): void => {
      setResetDialogIsOpen(false);
      setSnackbarOpen(true);
      setResetPasswordUsername("");
    };

    props.authClient.requestResetPassword(resetPasswordUsername, {
      onSuccess: resetStateAndShowSnackbar,
      onError: resetStateAndShowSnackbar,
    });
  };

  return (
    <>
      <LandingTopBar />
      <div className="container">
        <div className="row">
          <div className="col-sm-offset-2 col-sm-8 mtd mbm">
            <BackHomeButton href="/" />
            <h1 className="text-xxl mtl">Login</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-4 col-sm-4">
            <LoginForm authClient={props.authClient} />
            <div className="mtl">
              No account yet? <Link to="/register">Create one.</Link>
            </div>
            <div>
              Forgot your login?{" "}
              <button className="link plz" onClick={(): void => setResetDialogIsOpen(true)}>
                Reset your password.
              </button>
            </div>
          </div>
        </div>
      </div>
      <PopupDialog
        isOpen={resetDialogIsOpen}
        close={(): void => setResetDialogIsOpen(false)}
        description="Enter your username. If your account has an email on record, you'll receive a link via email to reset your password. The link will expire in one hour."
        title="Reset your password"
        callToActionText="Request password reset"
        onAction={requestPasswordReset}
      >
        <>
          <TextField
            margin="dense"
            id="username"
            name="username"
            label="Username"
            type="text"
            variant="filled"
            fullWidth
            onChange={(event): void => setResetPasswordUsername(event.target.value)}
            value={resetPasswordUsername}
          />
        </>
      </PopupDialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={(): void => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={(): void => setSnackbarOpen(false)} severity="success">
          Password reset requested. Check your email (or spam folder) for further actions.
        </Alert>
      </Snackbar>
    </>
  );
};
