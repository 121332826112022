import React, { ReactElement, useEffect, useState } from "react";
import { AppBar, Button, Drawer, Icon, List, ListItem, Toolbar, useMediaQuery } from "@material-ui/core";
import { navigate } from "@reach/router";
import pineLogo from "../pine-logo.svg";
import { MediaQueries } from "./MediaQueries";

interface Props {
  dark?: boolean;
}

export const LandingTopBar = (props: Props): ReactElement => {
  const isMobile = useMediaQuery(MediaQueries.isXXS);
  const [drawerOpen, toggleDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isMobile) {
      toggleDrawerOpen(false);
    }
  }, [isMobile]);

  const aboutButton = (): ReactElement => {
    return (
      <Button
        color="inherit"
        onClick={(): void => {
          navigate("/about");
        }}
      >
        About
      </Button>
    );
  };

  const logInButton = (): ReactElement => {
    return (
      <Button
        color="inherit"
        onClick={(): void => {
          navigate("/login");
        }}
      >
        Log in
      </Button>
    );
  };

  const signUpButton = (): ReactElement => {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={(): void => {
          navigate("/register");
        }}
      >
        Sign up
      </Button>
    );
  };

  const desktopActions = (): ReactElement => {
    return (
      <>
        <span className="mrm">{aboutButton()}</span>
        <span className="mrm">{logInButton()}</span>
        <span className="">{signUpButton()}</span>
      </>
    );
  };

  const mobileDrawer = (): ReactElement => {
    return (
      <>
        <Button onClick={(): void => toggleDrawerOpen(!drawerOpen)}>
          <Icon>{drawerOpen ? "close" : "menu"}</Icon>
        </Button>
      </>
    );
  };

  return (
    <>
      <AppBar position="sticky" color="default" style={{ zIndex: 1400 }}>
        <Toolbar disableGutters={true} className="container container-when-large">
          <a className="fdr fac no-link-format" href="/">
            <img style={{ height: "3rem" }} src={pineLogo} alt="" />
            <h1 className="text-xl font-logo">Pinelist</h1>
          </a>
          <div className="mla">{isMobile ? mobileDrawer() : desktopActions()}</div>
        </Toolbar>
      </AppBar>
      <div className={`width-100 pvs text-s ${props.dark ? "bg-gold" : "bg-almond"}`}>
        <div className="align-center">
          This app is in beta.{" "}
          <a
            className="text-dark"
            href="https://forms.gle/Exiv1FZT2fSKhb3w9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Let us know what you think.
          </a>
        </div>
      </div>
      {isMobile && (
        <Drawer
          anchor="top"
          open={drawerOpen}
          onClose={(): void => toggleDrawerOpen(false)}
          variant="persistent"
        >
          <List style={{ paddingTop: 60 }}>
            <ListItem
              button
              onClick={(): void => {
                navigate("/about");
              }}
            >
              {aboutButton()}
            </ListItem>
            <ListItem
              button
              onClick={(): void => {
                navigate("/login");
              }}
            >
              {logInButton()}
            </ListItem>
            <ListItem
              button
              onClick={(): void => {
                navigate("/register");
              }}
            >
              {signUpButton()}
            </ListItem>
          </List>
        </Drawer>
      )}
    </>
  );
};
