import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import { Button, FormControl, TextField } from "@material-ui/core";
import { navigate, RouteComponentProps } from "@reach/router";
import { AuthClient } from "../domain/Client";
import { User } from "../domain/types";
import { UserContext } from "../App";
import { UserActionType } from "../UserContext";
import { Alert } from "@material-ui/lab";

interface Props extends RouteComponentProps {
  authClient: AuthClient;
  onLogin?: (user: User) => void;
}

export const LoginForm = (props: Props): ReactElement => {
  const { dispatch } = React.useContext(UserContext);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [hasError, setError] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    props.authClient.login(username, password, {
      onSuccess: (user: User) => {
        setError(false);
        dispatch({ type: UserActionType.LOGIN, user: user });
        props.onLogin ? props.onLogin(user) : navigate("/home");
      },
      onError: () => {
        setError(true);
      },
    });
  };

  const handleUsernameInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setUsername(event.target.value);
  };

  const handlePasswordInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <div className="pbd">
          <TextField
            fullWidth
            label="Username"
            placeholder="Username"
            name="username"
            variant="filled"
            value={username}
            onChange={handleUsernameInput}
          />
        </div>
        <div className="pbd">
          <TextField
            fullWidth
            label="Password"
            placeholder="Password"
            name="password"
            variant="filled"
            type="password"
            value={password}
            onChange={handlePasswordInput}
          />
        </div>
        <Button data-testid="login-submit" color="primary" fullWidth type="submit" variant="contained">
          Log in
        </Button>
        {hasError && (
          <div className="ptd">
            <Alert severity="error">Username or password is incorrect.</Alert>
          </div>
        )}
      </FormControl>
    </form>
  );
};
