import axios, { AxiosError, AxiosResponse } from "axios";
import bcrypt from "bcryptjs";
import jwtDecode from "jwt-decode";
import { AuthClient, Observer } from "../domain/Client";
import { User } from "../domain/types";
import { Error } from "../domain/Error";

interface TokenResponse {
  success: boolean;
  token: string;
}

interface SuccessResponse {
  success: string;
}

interface JwtContents {
  user: User;
}

export const salt = "$2a$10$VN7iYAYEJ1JbauJPvct7m.";

export const decodeAndSetToken = (bearer: string): JwtContents => {
  const token = bearer.split(" ")[1];
  const decoded = jwtDecode(token) as JwtContents;
  window.localStorage.setItem("user", JSON.stringify(decoded.user));
  window.localStorage.setItem("token", bearer);
  return decoded;
};

export const ApiAuthClient = (): AuthClient => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const authPost = (endpoint: string, data: any, observer: Observer<User>): void => {
    axios
      .post(endpoint, data)
      .then((response: AxiosResponse<TokenResponse>) => {
        const decoded = decodeAndSetToken(response.data.token);
        observer.onSuccess(decoded.user);
      })
      .catch((errorResponse: AxiosError<TokenResponse>) => {
        if (errorResponse.response?.status === 404) {
          return observer.onError(Error.NOT_FOUND);
        } else if (errorResponse.response?.status === 401) {
          return observer.onError(Error.LOGIN_FAILED);
        } else if (errorResponse.response?.status === 409) {
          return observer.onError(Error.DATA_ALREADY_ADDED);
        }

        return observer.onError(Error.SYSTEM_ERROR);
      });
  };

  const login = (username: string, password: string, observer: Observer<User>): void => {
    const hashedPassword = bcrypt.hashSync(password, salt);
    authPost(
      "/api/auth/login",
      {
        username: username,
        password: hashedPassword,
      },
      observer
    );
  };

  const requestResetPassword = (username: string, observer: Observer<boolean>): void => {
    axios
      .post("/api/auth/request-reset-password", { username })
      .then((response: AxiosResponse<SuccessResponse>) => {
        observer.onSuccess(response.data.success === "true");
      })
      .catch((errorResponse: AxiosError<SuccessResponse>) => {
        if (errorResponse.response?.status === 404) {
          return observer.onError(Error.NOT_FOUND);
        } else if (errorResponse.response?.status === 401) {
          return observer.onError(Error.LOGIN_FAILED);
        }

        return observer.onError(Error.SYSTEM_ERROR);
      });
  };

  const validateResetPassword = (username: string, code: string, observer: Observer<User>): void => {
    authPost(
      "/api/auth/validate-reset-password",
      {
        username: username,
        code: code,
      },
      observer
    );
  };

  const register = (username: string, password: string, observer: Observer<User>): void => {
    const hashedPassword = bcrypt.hashSync(password, salt);
    authPost(
      "/api/auth/register",
      {
        username: username,
        password: hashedPassword,
      },
      observer
    );
  };

  return {
    login,
    register,
    requestResetPassword,
    validateResetPassword,
  };
};
