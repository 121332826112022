import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import { Button, FormControl, Icon, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { navigate, RouteComponentProps } from "@reach/router";
import { ActionClient, AuthClient } from "../domain/Client";
import { UserContext } from "../App";
import { useMountEffect } from "../helpers";
import { LandingTopBar } from "../components/LandingTopBar";
import queryString from "query-string";
import { ResetPasswordError, ResetPasswordErrorDecider } from "./ResetPasswordErrorDecider";
import { Alert } from "@material-ui/lab";
import { Error } from "../domain/Error";
import { UserTopBar } from "../components/UserTopBar";
import { UserActionType } from "../UserContext";
import { User } from "../domain/types";

interface Props extends RouteComponentProps {
  authClient: AuthClient;
  actionClient: ActionClient;
}

type Params = { user: string; code: string };

const ResetPasswordErrorLookup: Record<ResetPasswordError, string> = {
  PASSWORD_REQUIRED: "Password is required",
  GENERIC: "Sorry, something went wrong. Please try again later",
};

export const ResetPassword = (props: Props): ReactElement => {
  const { dispatch } = React.useContext(UserContext);
  const [newPassword, setNewPassword] = useState<string>("");
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<ResetPasswordError[]>([]);
  const [isValidatedParams, setIsValidatedParams] = useState<Params | undefined>(undefined);
  const [isValidationError, setIsValidationError] = useState<boolean>(false);

  useMountEffect(() => {
    if (!props.location?.search) {
      return;
    }

    const params = { ...queryString.parse(props.location.search) } as Params;
    props.authClient.validateResetPassword(params.user, params.code, {
      onSuccess: (user: User) => {
        setIsValidatedParams(params);
        dispatch({ type: UserActionType.LOGIN, user: user });
      },
      onError: () => {
        setIsValidationError(true);
      },
    });
  });

  const handleNewPasswordInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(event.target.value);
    setErrors(
      ResetPasswordErrorDecider("NEW_PASSWORD_ONCHANGE", errors, { newPassword: event.target.value }, null)
    );
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    if (!isValidatedParams) return;
    const newErrors = ResetPasswordErrorDecider("CLICK_UPDATE", errors, { newPassword }, null);
    setErrors(newErrors);

    if (newErrors.length > 0) {
      return;
    }

    props.actionClient.resetPasswordWithCode(isValidatedParams.code, newPassword, {
      onSuccess: () => {
        navigate("/home?reset-password=success");
        setNewPassword("");
        setErrors(ResetPasswordErrorDecider("ON_SUCCESS", errors, { newPassword }, null));
      },
      onError: (error: Error) => {
        setErrors(ResetPasswordErrorDecider("ON_ERROR", errors, { newPassword }, error));
      },
    });
  };

  if (isValidationError) {
    return (
      <>
        <LandingTopBar />
        <div className="container mtl">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <h1 className="text-xxl">Sorry, something went wrong.</h1>
              <p>Your password reset code is invalid or expired.</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!isValidatedParams) {
    return (
      <>
        <LandingTopBar />
      </>
    );
  }

  return (
    <>
      <UserTopBar actionClient={props.actionClient} />
      <div className="container">
        <div className="row">
          <div className="col-sm-offset-2 col-sm-8 mtd mbm">
            <h1 className="text-xxl mtl">Welcome, {isValidatedParams.user}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-4 col-sm-4">
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth>
                <h2 className="text-l mbd">Let's set a new password.</h2>
                <TextField
                  fullWidth
                  label="New password"
                  placeholder="New password"
                  name="new-password"
                  variant="filled"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={handleNewPasswordInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(): void => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="mtd">
                  <Button color="primary" fullWidth type="submit" variant="contained">
                    Update password
                  </Button>
                </div>
              </FormControl>
              {errors.length > 0 && (
                <div className="pvd">
                  <Alert severity="error">{ResetPasswordErrorLookup[errors[0]]}</Alert>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
