import React, { ReactElement, ReactNode, useEffect } from "react";
import {
  Button,
  Divider,
  Icon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { MediaQueries } from "../components/MediaQueries";
import { CommentCountIcon } from "./CommentCountIcon";
import { PinelistSection } from "./PinelistSection";
import { ItemShared, User } from "../domain/types";
import { Username } from "../components/Username";
import { ColoredAvatar } from "../components/ColoredAvatar";
import { navigate } from "@reach/router";

type ActionType = "EDIT" | "DELETE" | "ARCHIVE" | "UNARCHIVE" | "COPY";

export type Action = {
  type: ActionType;
  callback: () => void;
};

const getActionHoverButtonLookup = (actions: Action[]): Record<ActionType, ReactNode> => {
  const getCallback = (type: ActionType): (() => void) => {
    const action = actions.find((it) => it.type === type);
    return action?.callback ?? ((): void => {});
  };

  return {
    ARCHIVE: (
      <IconButton key="archive_icon" edge="end" aria-label="archive" onClick={getCallback("ARCHIVE")}>
        <Icon>archive</Icon>
      </IconButton>
    ),
    UNARCHIVE: (
      <IconButton key="unarchive_icon" edge="end" aria-label="unarchive" onClick={getCallback("UNARCHIVE")}>
        <Icon>unarchive</Icon>
      </IconButton>
    ),
    EDIT: (
      <IconButton key="edit_icon" edge="end" aria-label="edit" onClick={getCallback("EDIT")}>
        <Icon>edit</Icon>
      </IconButton>
    ),
    DELETE: (
      <IconButton key="delete_icon" edge="end" aria-label="delete" onClick={getCallback("DELETE")}>
        <Icon>delete</Icon>
      </IconButton>
    ),
    COPY: (
      <IconButton key="copy_icon" edge="end" aria-label="copy" onClick={getCallback("COPY")}>
        <Icon>content_copy</Icon>
      </IconButton>
    ),
  };
};

const getActionMenuItemLookup = (actions: Action[]): Record<ActionType, ReactNode> => {
  const getCallback = (type: ActionType): (() => void) => {
    const action = actions.find((it) => it.type === type);
    return action?.callback ?? ((): void => {});
  };

  return {
    ARCHIVE: (
      <MenuItem key="archive_menu" aria-label="archive" onClick={getCallback("ARCHIVE")}>
        <Icon>archive</Icon>
        <span className="mhd all-caps">Archive</span>
      </MenuItem>
    ),
    UNARCHIVE: (
      <MenuItem key="unarchive_menu" aria-label="unarchive" onClick={getCallback("UNARCHIVE")}>
        <Icon>unarchive</Icon>
        <span className="mhd all-caps">Unarchive</span>
      </MenuItem>
    ),
    EDIT: (
      <MenuItem key="edit_menu" aria-label="edit" onClick={getCallback("EDIT")}>
        <Icon>edit</Icon>
        <span className="mhd all-caps">Edit</span>
      </MenuItem>
    ),
    DELETE: (
      <MenuItem key="delete_menu" aria-label="delete" onClick={getCallback("DELETE")}>
        <Icon>delete</Icon>
        <span className="mhd all-caps">Delete</span>
      </MenuItem>
    ),
    COPY: (
      <MenuItem key="copy_menu" aria-label="copy" onClick={getCallback("COPY")}>
        <Icon>content_copy</Icon>
        <span className="mhd all-caps">Copy</span>
      </MenuItem>
    ),
  };
};

interface Props {
  textPrimary: ReactNode;
  textSecondary: string;
  actions: Action[];
  commentCount: number | undefined;
  claimedBy: User | undefined;
  ownedBy: User | undefined;
  item?: ItemShared;
  onClick?: () => void;
  href?: string | undefined;
  section: "not-section" | "editable-section" | "uneditable-section";
}

export const LineItem = (props: Props): ReactElement => {
  const isTouchscreen = useMediaQuery(MediaQueries.isTouchscreen);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [actions, setActions] = React.useState<Action[]>([]);

  const actionHoverButtonLookup = getActionHoverButtonLookup(props.actions);
  const actionMenuItemLookup = getActionMenuItemLookup(props.actions);

  const isSection = props.section !== "not-section";

  useEffect((): void => {
    (function removeCopyOptionForSections(): void {
      if (isSection) {
        setActions(props.actions.filter((it) => it.type !== "COPY"));
      } else {
        setActions(props.actions);
      }
    })();
  }, [props.actions, props.section, isSection]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const primaryText = props.commentCount ? (
    <div className="fdr fac">
      <CommentCountIcon count={props.commentCount} />
      <span className="mls">{props.textPrimary}</span>
    </div>
  ) : (
    <>{props.textPrimary}</>
  );

  const withLink = (children: ReactNode): ReactNode => {
    if (props.href) {
      return (
        <a href={props.href} className="button-styled-href" data-testid="button-link">
          {children}
        </a>
      );
    } else {
      return children;
    }
  };

  return (
    <div className={`onhover-parent ${isSection ? "mtl" : ""}`}>
      <ListItem
        button={!isSection as false | undefined}
        data-testid="pinelist-item"
        onClick={!isSection ? props.onClick ?? ((): Promise<void> => navigate(props.href || "")) : undefined}
        style={isSection ? { paddingLeft: 0 } : {}}
      >
        {!isSection ? (
          <>
            {withLink(
              <ListItemText
                data-testid="item"
                primary={primaryText}
                secondary={props.textSecondary}
                secondaryTypographyProps={{ noWrap: true }}
              />
            )}
          </>
        ) : (
          <PinelistSection sectionItem={props.item} editable={props.section === "editable-section"} />
        )}
        <ListItemSecondaryAction>
          <div className="fdr">
            {!isTouchscreen && actions.length > 0 && (
              <div className={`onhover-child ${props.ownedBy !== undefined ? "mrd" : ""}`}>
                {actions.map((it) => actionHoverButtonLookup[it.type])}
              </div>
            )}
            {props.ownedBy !== undefined && (
              <Button href={`/profile/${props.ownedBy?.id ?? ""}`} data-testid="button-link">
                <Username username={props.ownedBy.username} />
              </Button>
            )}
            {props.claimedBy !== undefined && (
              <>
                <ListItemAvatar className="mrxl">
                  <ColoredAvatar text={props.claimedBy?.username} size="medium" color="grey" />
                </ListItemAvatar>
                <ListItemSecondaryAction style={{ right: "0px" }} className="bold-caps text-grey">
                  Claimed
                </ListItemSecondaryAction>
              </>
            )}
            {isTouchscreen && actions.length > 0 && (
              <>
                <IconButton edge="end" aria-label="more_vert" onClick={handleClick}>
                  <Icon>more_vert</Icon>
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  {actions.map((it) => actionMenuItemLookup[it.type])}
                </Menu>
              </>
            )}
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </div>
  );
};
