import React, { ReactElement } from "react";
import { Link } from "@reach/router";
import { LandingTopBar } from "./components/LandingTopBar";
import { useDocTitle } from "./hooks/useDocTitle";

interface Props {
  type?: ErrorPageType;
}

export type ErrorPageType = "LOGGED_OUT" | "GENERIC" | "NOT_ALLOWED";

const MessageLookup: Record<ErrorPageType, { title: string; text: string }> = {
  LOGGED_OUT: {
    title: "You've been logged out",
    text: "",
  },
  GENERIC: {
    title: "Sorry, something went wrong",
    text: "Please refresh the page or try again later.",
  },
  NOT_ALLOWED: {
    title: "Sorry, that's not allowed",
    text: "You're not part of this list or group.",
  },
};

export const ErrorPage = (props: Props): ReactElement => {
  useDocTitle(undefined);

  const type = props.type ?? "GENERIC";
  const { title, text } = MessageLookup[type];

  return (
    <>
      <LandingTopBar />
      <div className="container mtl">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <h1 className="text-xxl">{title}</h1>
            {text && <p>{text}</p>}
            <p>
              Here by mistake? <Link to="/home">Get home.</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
