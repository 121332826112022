import React, { ReactElement } from "react";
import { EmptyState } from "../components/EmptyState";
import { List, useMediaQuery } from "@material-ui/core";
import { ActionClient } from "../domain/Client";
import { PinelistOverview } from "../domain/types";
import { ErrorPageType } from "../ErrorPage";
import { Error } from "../domain/Error";
import { MediaQueries } from "../components/MediaQueries";
import { LineItem } from "../pinelists/LineItem";

interface Props {
  isLoading: boolean;
  actionClient: ActionClient;
  asGiverPinelists: PinelistOverview[];
  getAndSetLists: () => void;
  setShowErrorPage: (errorType: ErrorPageType) => void;
}

export const SharedWithMeColumn = (props: Props): ReactElement => {
  const isXS = useMediaQuery(MediaQueries.isXS);

  const onArchivePinelist = (id: string): void => {
    props.actionClient.archivePinelist(id, {
      onSuccess: () => {
        props.getAndSetLists();
      },
      onError: (error: Error) => {
        props.setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  };

  return (
    <div className={isXS ? "col-xs-12" : ""}>
      {!isXS && <h2 className="text-xl mtl">Shared with me</h2>}
      {props.isLoading && <p>Loading...</p>}
      {!props.isLoading && props.asGiverPinelists.length === 0 && (
        <EmptyState
          header="Nothing here yet!"
          body="When someone adds you as a Giver on their list, it will show up here."
        />
      )}
      <List>
        {props.asGiverPinelists.map((pinelist) => (
          <LineItem
            key={pinelist.id}
            textPrimary={pinelist.name}
            textSecondary=""
            href={`/pinelists/${pinelist.id}/as/giver`}
            section="not-section"
            commentCount={undefined}
            claimedBy={undefined}
            ownedBy={pinelist.owner}
            actions={[{ type: "ARCHIVE", callback: (): void => onArchivePinelist(pinelist.id) }]}
          />
        ))}
      </List>
    </div>
  );
};
