import {
  Button,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import { ChangePasswordError, ChangePasswordErrorDecider } from "./ChangePasswordErrorDecider";
import { ActionClient } from "../domain/Client";
import { Error } from "../domain/Error";

const ChangePasswordErrorLookup: Record<ChangePasswordError, string> = {
  PASSWORDS_REQUIRED: "Passwords are required",
  CURRENT_PASSWORD_INCORRECT: "Incorrect password",
  GENERIC: "Sorry, something went wrong. Please try again later",
};

interface Props {
  actionClient: ActionClient;
}

export const ChangePassword = (props: Props): ReactElement => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<ChangePasswordError[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const handleCurrentPasswordInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setCurrentPassword(event.target.value);
    setErrors(
      ChangePasswordErrorDecider(
        "CURRENT_PASSWORD_ONCHANGE",
        errors,
        {
          currentPassword: event.target.value,
          newPassword,
        },
        null
      )
    );
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    const newErrors = ChangePasswordErrorDecider(
      "CLICK_UPDATE",
      errors,
      { currentPassword, newPassword },
      null
    );
    setErrors(newErrors);

    if (newErrors.length > 0) {
      return;
    }

    props.actionClient.changePassword(currentPassword, newPassword, {
      onSuccess: () => {
        setErrors(ChangePasswordErrorDecider("ON_SUCCESS", errors, { currentPassword, newPassword }, null));
        setSnackbarOpen(true);
        setCurrentPassword("");
        setNewPassword("");
      },
      onError: (error: Error) => {
        setErrors(ChangePasswordErrorDecider("ON_ERROR", errors, { currentPassword, newPassword }, error));
      },
    });
  };

  const handleNewPasswordInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(event.target.value);
    setErrors(
      ChangePasswordErrorDecider(
        "NEW_PASSWORD_ONCHANGE",
        errors,
        {
          currentPassword,
          newPassword: event.target.value,
        },
        null
      )
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <div className="pbd">
          <TextField
            fullWidth
            label="Current password"
            placeholder="Current password"
            name="current-password"
            variant="filled"
            value={currentPassword}
            type={showCurrentPassword ? "text" : "password"}
            onChange={handleCurrentPasswordInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(): void => setShowCurrentPassword(!showCurrentPassword)}
                  >
                    {showCurrentPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="pbd">
          <TextField
            fullWidth
            label="New password"
            placeholder="New password"
            name="new-password"
            variant="filled"
            type={showNewPassword ? "text" : "password"}
            value={newPassword}
            onChange={handleNewPasswordInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(): void => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {errors.length > 0 && (
          <div className="pvd">
            <Alert severity="error">{ChangePasswordErrorLookup[errors[0]]}</Alert>
          </div>
        )}
        <Button color="primary" fullWidth type="submit" variant="contained">
          Update password
        </Button>
      </FormControl>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={(): void => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={(): void => setSnackbarOpen(false)} severity="success">
          Password successfully updated
        </Alert>
      </Snackbar>
    </form>
  );
};
