import { Icon, makeStyles } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "medium",
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: "large",
  },
  primary: {
    color: "#fff",
    backgroundColor: "#047f57",
  },
  grey: {
    color: "#fff",
    backgroundColor: "#bdbdbd",
  },
}));

interface Props {
  text: string;
  size: "small" | "medium";
  color: "grey" | "primary";
}

export const ColoredAvatar = (props: Props): ReactElement => {
  const classes = useStyles();
  const letter = props.text.substr(0, 1).toUpperCase();

  return (
    <Avatar alt={props.text} className={`mrs ${classes[props.size]} ${classes[props.color]}`}>
      {letter}
    </Avatar>
  );
};

export const ColoredAvatarIcon = (props: Props): ReactElement => {
  const classes = useStyles();
  return (
    <Avatar alt={props.text} className={`mrs ${classes[props.size]} ${classes[props.color]}`}>
      <Icon fontSize="small">{props.text}</Icon>
    </Avatar>
  );
};
