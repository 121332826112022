import React, { ReactElement } from "react";
import { Icon } from "@material-ui/core";

interface Props {
  count: number;
}

export const CommentCountIcon = (props: Props): ReactElement => {
  const className = props.count < 10 ? "display-count" : "display-count-longer";
  const visibility = props.count === 0 ? "hidden" : "visible";

  return (
    <div
      style={{ height: "1rem", marginBottom: ".5rem", visibility }}
      data-count={props.count}
      className={className}
    >
      <Icon style={{ color: "#bdbdbd", fontSize: "28px" }}>chat_bubble</Icon>
    </div>
  );
};
