/* eslint-disable jsx-a11y/no-autofocus */

import React, { ChangeEvent, ReactElement, useState } from "react";
import { InlineTextField } from "../components/InlineTextField";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { LineItem } from "../pinelists/LineItem";
import { navigate } from "@reach/router";
import { MediaQueries } from "../components/MediaQueries";
import { PinelistAsOwner, PinelistOverview } from "../domain/types";
import { Alert } from "@material-ui/lab";
import { Error } from "../domain/Error";
import { ActionClient } from "../domain/Client";
import { ErrorPageType } from "../ErrorPage";
import { useCreateCollectionErrors } from "../hooks/useCreateCollectionErrors";
import { CreateCollectionErrorDecider } from "./CreateCollectionErrorDecider";
import { AreYouSureDialog } from "../components/AreYouSureDialog";

interface Props {
  isLoading: boolean;
  actionClient: ActionClient;
  asOwnerPinelists: PinelistOverview[];
  getAndSetLists: () => void;
  setShowErrorPage: (errorType: ErrorPageType) => void;
}

export const MyPinelistsColumn = (props: Props): ReactElement => {
  const isXS = useMediaQuery(MediaQueries.isXS);
  const [createDialogIsOpen, setCreateDialogIsOpen] = React.useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string>("");
  const [editingPinelist, setEditingPinelist] = React.useState<PinelistOverview | undefined>(undefined);
  const [newPinelistName, setNewPinelistName] = React.useState<string>("");
  const { errors, setErrors, errorLookup } = useCreateCollectionErrors("Pinelist");

  const openCreateDialog = (): void => {
    setCreateDialogIsOpen(true);
  };

  const closeCreateDialog = (): void => {
    setCreateDialogIsOpen(false);
  };

  const onDeletePinelist = (id: string): void => {
    props.actionClient.deletePinelist(id, {
      onSuccess: () => {
        props.getAndSetLists();
        setIdToDelete("");
      },
      onError: (error: Error) => {
        props.setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  };

  const onArchivePinelist = (id: string): void => {
    props.actionClient.archivePinelist(id, {
      onSuccess: () => {
        props.getAndSetLists();
      },
      onError: (error: Error) => {
        props.setShowErrorPage(error === Error.USER_SIGNED_OUT ? "LOGGED_OUT" : "GENERIC");
      },
    });
  };

  const onEditPinelist = (pinelist: PinelistOverview): void => {
    setEditingPinelist(pinelist);
    setNewPinelistName(pinelist.name);
    openCreateDialog();
  };

  const handlePinelistNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newName = event.target.value;
    setNewPinelistName(newName);
    setErrors(CreateCollectionErrorDecider("NAME_ONCHANGE", errors, newName));
  };

  const createNewPinelist = (): void => {
    const newErrors = CreateCollectionErrorDecider("CLICK_CREATE", errors, newPinelistName);
    setErrors(newErrors);

    if (newErrors.length > 0) {
      return;
    }

    if (editingPinelist) {
      props.actionClient.editPinelist(
        {
          id: editingPinelist.id,
          newName: newPinelistName,
        },
        {
          onSuccess: () => {
            setErrors(CreateCollectionErrorDecider("ON_SUCCESS", errors, newPinelistName));
            props.getAndSetLists();
            setNewPinelistName("");
            setEditingPinelist(undefined);
            closeCreateDialog();
          },
          onError: () => {
            setErrors(CreateCollectionErrorDecider("ON_ERROR", errors, newPinelistName));
          },
        }
      );
    } else {
      props.actionClient.createPinelist(newPinelistName, {
        onSuccess: (pinelist: PinelistAsOwner) => {
          setErrors(CreateCollectionErrorDecider("ON_SUCCESS", errors, newPinelistName));
          navigate(`/pinelists/${pinelist.id}/as/owner`);
        },
        onError: () => {
          setErrors(CreateCollectionErrorDecider("ON_ERROR", errors, newPinelistName));
        },
      });
    }
  };

  return (
    <>
      <div className={isXS ? "col-xs-12" : ""}>
        {!isXS && <h2 className="text-xl mtl">My pinelists</h2>}
        <div className="mvm">
          <InlineTextField
            onChange={handlePinelistNameChange}
            submit={createNewPinelist}
            value={newPinelistName}
            name="list-name"
            label="Name of list"
            submitButtonText="Create"
            error={errors.length > 0 ? errorLookup[errors[0]] : undefined}
            buttonType="primary"
          />
        </div>
        {props.isLoading && <p>Loading...</p>}
        <List>
          {props.asOwnerPinelists.map((pinelist) => (
            <div key={pinelist.id}>
              <LineItem
                textPrimary={pinelist.name}
                textSecondary=""
                href={`/pinelists/${pinelist.id}/as/owner`}
                commentCount={undefined}
                claimedBy={undefined}
                ownedBy={undefined}
                section="not-section"
                actions={[
                  { type: "EDIT", callback: (): void => onEditPinelist(pinelist) },
                  { type: "ARCHIVE", callback: (): void => onArchivePinelist(pinelist.id) },
                  { type: "DELETE", callback: (): void => setIdToDelete(pinelist.id) },
                ]}
              />
            </div>
          ))}
        </List>
      </div>
      <AreYouSureDialog
        isOpen={idToDelete.length > 0}
        cancelCallback={(): void => setIdToDelete("")}
        primaryCallback={(): void => onDeletePinelist(idToDelete)}
        bodyText="If you delete this pinelist, all items and comments will also be deleted. Givers will no longer
            have access. All data will be removed and unrecoverable. Are you sure you want to delete?"
      />
      <Dialog open={createDialogIsOpen} onClose={closeCreateDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {editingPinelist ? `Editing ${editingPinelist.name}` : "Create new list"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a name for your pinelist. We recommend including your name, the holiday, and the year or
            date.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="list-name"
            name="list-name"
            label="Name of list"
            variant="filled"
            type="text"
            fullWidth
            value={newPinelistName}
            onChange={handlePinelistNameChange}
          />
          {errors.length > 0 && (
            <div className="ptd">
              <Alert severity="error">{errorLookup[errors[0]]}</Alert>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateDialog} color="primary">
            Cancel
          </Button>
          <Button
            id={editingPinelist ? "save" : "create"}
            onClick={createNewPinelist}
            variant="contained"
            color="primary"
          >
            {editingPinelist ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
