import React, { ChangeEvent, ReactElement, useContext } from "react";
import { Button } from "@material-ui/core";
import { InlineTextField } from "../../components/InlineTextField";
import { AddItemErrorLookup } from "./AddItemDialog";
import { AddItemContext } from "./AddItemContext";

interface Props {
  addButtonType: "primary" | "secondary";
}

export const AddItemInline = (props: Props): ReactElement => {
  const { state, actions } = useContext(AddItemContext);

  const addDetailsButton = (
    <Button variant="text" color="primary" onClick={(): void => actions.openDialog(false)}>
      <span className="">Add details {">"}</span>
    </Button>
  );

  return (
    <InlineTextField
      onChange={(e: ChangeEvent<HTMLInputElement>): void => actions.changeTitle(e.target.value)}
      submit={actions.submit}
      value={state.dialogIsOpen ? "" : state.title}
      name="item-title"
      label="Item Title"
      submitButtonText="Add"
      secondaryElement={addDetailsButton}
      error={state.errors.length > 0 ? AddItemErrorLookup[state.errors[0]] : undefined}
      buttonType={props.addButtonType}
    />
  );
};
