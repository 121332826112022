import React, { ReactElement, ReactNode } from "react";
import { CommentsList } from "./CommentsList";
import { AddCommentField } from "./AddCommentField";
import { Comment, User } from "../../domain/types";
import { ActionClient } from "../../domain/Client";

interface Props {
  pinelistId: string;
  comments: Comment[];
  owner: User;
  actionClient: ActionClient;
  setCommentCallback: (comment: Comment) => void;
  deleteCommentCallback: (commentId: string) => void;
  giverView: boolean;
  headerSize: "h2" | "h3";
  itemId: string;
  allowSwitchingVisibility: boolean;
}

export const CommentSection = (props: Props): ReactElement => {
  const getHeader = (): ReactNode => {
    switch (props.headerSize) {
      case "h2":
        return <h2 className="text-l mrl mtl mbd">Comments</h2>;
      case "h3":
        return <h3 className="text-m weight-500 mvm">Comments</h3>;
    }
  };

  return (
    <>
      {getHeader()}
      <CommentsList
        actionClient={props.actionClient}
        pinelistId={props.pinelistId}
        comments={props.comments}
        owner={props.owner}
        deleteCommentCallback={props.deleteCommentCallback}
      />
      <AddCommentField
        allowSwitchingVisibility={props.allowSwitchingVisibility}
        actionClient={props.actionClient}
        giverView={props.giverView}
        itemId={props.itemId}
        pinelistId={props.pinelistId}
        setCommentCallback={props.setCommentCallback}
      />
    </>
  );
};
