import React, { ReactElement } from "react";

export const CopyrightFooter = (): ReactElement => {
  return (
    <div className="bg-white footer height-15">
      <div className="container">
        <div>&copy; 2023 Anne LoVerso</div>
        <div>
          <a href="https://iconscout.com/icons/pine" target="_blank" rel="noopener noreferrer">
            Pine Icon
          </a>{" "}
          by <a href="https://iconscout.com/contributors/delesign">Delesign Graphics</a> on{" "}
          <a href="https://iconscout.com">Iconscout</a>
        </div>
      </div>
    </div>
  );
};
