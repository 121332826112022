import React, { ReactElement, useContext, useReducer } from "react";
import { Redirect, Router } from "@reach/router";
import { LandingPage } from "./LandingPage";
import { UserActionType, UserContextType, userReducer, UserReducer } from "./UserContext";
import { useMountEffect } from "./helpers";
import { ApiAuthClient } from "./api/ApiAuthClient";
import { ApiActionClient } from "./api/ApiActionClient";
import { Login } from "./signing-in/Login";
import { Register } from "./signing-in/Register";
import { Home } from "./home/Home";
import { PinelistOwnerPage } from "./pinelists/as-owner/PinelistOwnerPage";
import { PinelistGiverPage } from "./pinelists/PinelistGiverPage";
import { GroupPage } from "./groups/GroupPage";
import { AccountSettingsPage } from "./account-settings/AccountSettingsPage";
import { InvitePage } from "./invite/InvitePage";
import { ResetPassword } from "./signing-in/ResetPassword";
import { AccountNotificationsPage } from "./account-settings/AccountNotificationsPage";
import { ProfilePage } from "./profile/ProfilePage";
import { AboutPage } from "./AboutPage";

const initialState = {
  user: undefined,
  isAuth: false,
};

export const UserContext = React.createContext<UserContextType>({
  dispatch: () => {},
  state: initialState,
});

export const App = (): ReactElement => {
  const apiAuthClient = ApiAuthClient();
  const apiActionClient = ApiActionClient();
  const [state, dispatch] = useReducer<UserReducer>(userReducer, initialState);

  useMountEffect(() => {
    const existingUserSession = window.localStorage.getItem("user");
    if (existingUserSession) {
      dispatch({ type: UserActionType.LOGIN, user: JSON.parse(existingUserSession) });
    }
  });

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <Router>
        <LandingPage path="/" />
        <AboutPage path="/about" />

        <PublicRoute
          path="/invite/:inviteId"
          component={InvitePage}
          actionClient={apiActionClient}
          authClient={apiAuthClient}
        />

        <PublicOnlyRoute path="/login" component={Login} authClient={apiAuthClient} />
        <PublicOnlyRoute path="/register" component={Register} authClient={apiAuthClient} />
        <PublicRoute
          path="/reset-password"
          component={ResetPassword}
          authClient={apiAuthClient}
          actionClient={apiActionClient}
        />
        <ProtectedRoute path="/home" component={Home} actionClient={apiActionClient} />
        <ProtectedRoute path="/groups/:id" component={GroupPage} actionClient={apiActionClient} />
        <ProtectedRoute
          path="/account-settings"
          component={AccountSettingsPage}
          actionClient={apiActionClient}
        />
        <ProtectedRoute
          path="/account/notifications"
          component={AccountNotificationsPage}
          actionClient={apiActionClient}
        />
        <ProtectedRoute
          path="/pinelists/:id/as/owner"
          component={PinelistOwnerPage}
          actionClient={apiActionClient}
        />
        <ProtectedRoute
          path="/pinelists/:id/as/giver"
          component={PinelistGiverPage}
          actionClient={apiActionClient}
        />
        <ProtectedRoute path="/profile/:id" component={ProfilePage} actionClient={apiActionClient} />
      </Router>
    </UserContext.Provider>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const PublicRoute = ({ component, ...rest }: any): ReactElement => {
  return React.createElement(component, { ...rest });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const ProtectedRoute = ({ component, ...rest }: any): ReactElement => {
  const { state, dispatch } = useContext(UserContext);
  const existingUserSession = window.localStorage.getItem("user");

  if (!existingUserSession) {
    if (state.isAuth) {
      dispatch({ type: UserActionType.LOGOUT, user: undefined });
    }
    const url = encodeURIComponent(rest.location.pathname);
    return <Redirect to={`/login?url=${url}`} noThrow />;
  }

  return React.createElement(component, { ...rest });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const PublicOnlyRoute = ({ component, ...rest }: any): ReactElement => {
  const { state } = useContext(UserContext);
  const existingUserSession = window.localStorage.getItem("user");

  if (state.isAuth || existingUserSession) {
    return <Redirect to="/home" noThrow />;
  }

  return React.createElement(component, { ...rest });
};
