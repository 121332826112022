import { CreateCollectionError } from "../hooks/useCreateCollectionErrors";

export type CreateCollectionEvent = "NAME_ONCHANGE" | "CLICK_CREATE" | "ON_SUCCESS" | "ON_ERROR";

const remove = (error: CreateCollectionError, errors: CreateCollectionError[]): CreateCollectionError[] => {
  return errors.filter((it) => it !== error);
};

const add = (error: CreateCollectionError, errors: CreateCollectionError[]): CreateCollectionError[] => {
  return [error, ...errors];
};

export const CreateCollectionErrorDecider = (
  event: CreateCollectionEvent,
  currentErrors: CreateCollectionError[],
  value: string
): CreateCollectionError[] => {
  let newErrors = currentErrors;
  switch (event) {
    case "NAME_ONCHANGE":
      newErrors = remove("NAME_REQUIRED", newErrors);
      if (value.length > 64) {
        newErrors = add("NAME_TOO_LONG", newErrors);
      } else {
        newErrors = remove("NAME_TOO_LONG", newErrors);
      }
      break;
    case "CLICK_CREATE":
      newErrors = remove("GENERIC", newErrors);
      if (!value) {
        newErrors = add("NAME_REQUIRED", newErrors);
      }
      break;
    case "ON_SUCCESS":
      newErrors = [];
      break;
    case "ON_ERROR":
      newErrors = add("GENERIC", newErrors);
      break;
  }

  return newErrors;
};
