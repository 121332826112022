/* eslint-disable jsx-a11y/no-autofocus */

import React, { ReactElement, useContext } from "react";
import { TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { PopupDialog } from "../../components/PopupDialog";
import { AddItemError } from "./AddItemErrorDecider";
import { AddItemContext } from "./AddItemContext";

export const AddItemErrorLookup: Record<AddItemError, string> = {
  TITLE_TOO_LONG: "Item title must be 64 characters or fewer",
  GENERIC: "Sorry, something went wrong. Please try again later",
  TITLE_REQUIRED: "Item title is required",
};

export const AddItemDialog = (): ReactElement => {
  const { state, actions } = useContext(AddItemContext);

  const getTitle = (): string => {
    if (state.isSection) {
      return "Add section";
    } else {
      return "Add item";
    }
  };

  const getDescription = (): string => {
    if (state.isSection) {
      return "Enter a title for your section.";
    }

    return "Enter your item title, and optionally add some details: such as color, clothing size, or a link to purchase.";
  };

  const close = (): void => {
    actions.closeDialog();
  };

  const itemFields = (): ReactElement => {
    return (
      <>
        <TextField
          autoFocus
          margin="dense"
          id="item-title"
          name="item-title"
          label="Item"
          type="text"
          variant="filled"
          fullWidth
          onChange={(event): void => actions.changeTitle(event.target.value)}
          defaultValue={state.title}
        />
        <TextField
          margin="dense"
          id="item-details"
          name="item-details"
          label="Details"
          type="text"
          variant="filled"
          fullWidth
          multiline
          minRows={6}
          onChange={(event): void => actions.changeDetails(event.target.value)}
          defaultValue={state.details}
        />
      </>
    );
  };

  const sectionFields = (): ReactElement => {
    return (
      <TextField
        autoFocus
        margin="dense"
        id="section-title"
        name="section-title"
        label="Section title"
        type="text"
        variant="filled"
        fullWidth
        onChange={(event): void => actions.changeTitle(event.target.value)}
        defaultValue={state.title}
      />
    );
  };

  return (
    <>
      <PopupDialog
        isOpen={state.dialogIsOpen}
        close={close}
        title={getTitle()}
        description={getDescription()}
        callToActionText="Add"
        onAction={actions.submit}
      >
        <div data-testid="add-dialog">
          {state.isSection ? sectionFields() : itemFields()}
          {state.errors.length > 0 && (
            <div className="pvd">
              <Alert severity="error">{AddItemErrorLookup[state.errors[0]]}</Alert>
            </div>
          )}
        </div>
      </PopupDialog>
    </>
  );
};
