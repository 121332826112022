import { ItemAsOwner } from "../../domain/types";
import React, { createContext, ReactElement, ReactNode, useState } from "react";
import { AddItemError, AddItemErrorDecider } from "./AddItemErrorDecider";
import { ActionClient } from "../../domain/Client";

type EditingFieldTypes = "title" | "details" | "section-title" | "";

export interface EditItemState {
  title: string;
  details: string;
  itemToEdit: ItemAsOwner | undefined;
  errors: AddItemError[];
  isSection: boolean;
  editingField: EditingFieldTypes;
}

export interface EditItemActions {
  changeTitle: (title: string) => void;
  changeDetails: (details: string) => void;
  setEditing: (params: {
    itemToEdit: ItemAsOwner | undefined;
    isSection: boolean;
    editingField: EditingFieldTypes;
    dialogIsOpen: boolean;
  }) => void;
  submit: () => void;
  stopEditing: () => void;
}

export interface EditItemContextType {
  state: EditItemState;
  actions: EditItemActions;
}

const initialState = {
  title: "",
  details: "",
  itemToEdit: undefined,
  errors: [],
  isSection: false,
  editingField: "" as EditingFieldTypes,
};

export const EditItemContext = createContext<EditItemContextType>({
  state: initialState,
  actions: {
    changeTitle: (): void => {},
    changeDetails: (): void => {},
    setEditing: (): void => {},
    submit: (): void => {},
    stopEditing: (): void => {},
  },
});

interface Props {
  actionClient: ActionClient;
  pinelistId: string;
  children: ReactNode;
  onSuccessfulEdit: (item: ItemAsOwner) => void;
}

export const EditItemProvider = (props: Props): ReactElement => {
  const [state, setState] = useState<EditItemState>(initialState);

  const changeTitle = (title: string): void => {
    const errors = AddItemErrorDecider("TITLE_ONCHANGE", state.errors, { title, details: state.details });
    setState({ ...state, title, errors });
  };

  const changeDetails = (details: string): void => {
    setState({ ...state, details });
  };

  const stopEditing = (): void => {
    setState({
      ...state,
      isSection: false,
      title: "",
      details: "",
      itemToEdit: undefined,
      errors: AddItemErrorDecider("ON_START_STOP_EDITING", state.errors, {
        title: "",
        details: "",
      }),
    });
  };

  const setEditing = (params: {
    itemToEdit: ItemAsOwner | undefined;
    isSection: boolean;
    editingField: EditingFieldTypes;
    dialogIsOpen: boolean;
  }): void => {
    setState({
      ...state,
      isSection: params.isSection,
      title: params.itemToEdit?.title || "",
      details: params.itemToEdit?.details || "",
      itemToEdit: params.itemToEdit,
      editingField: params.editingField,
      errors: AddItemErrorDecider("ON_START_STOP_EDITING", state.errors, {
        title: params.itemToEdit?.title || "",
        details: params.itemToEdit?.title || "",
      }),
    });
  };

  const submit = (): void => {
    const newErrors = AddItemErrorDecider("CLICK_ADD", state.errors, {
      title: state.title,
      details: state.details,
    });
    setState({ ...state, errors: newErrors });

    if (newErrors.length > 0 || !state.itemToEdit) {
      return;
    }

    const observer = {
      onSuccess: (item: ItemAsOwner): void => {
        setState({
          ...state,
          errors: AddItemErrorDecider("ON_SUCCESS", state.errors, {
            title: state.title,
            details: state.details,
          }),
        });
        props.onSuccessfulEdit(item);
        setState({
          ...state,
          title: "",
          details: "",
          itemToEdit: undefined,
          isSection: false,
          editingField: "",
        });
      },
      onError: (): void => {
        setState({
          ...state,
          errors: AddItemErrorDecider("ON_ERROR", state.errors, {
            title: state.title,
            details: state.details,
          }),
        });
      },
    };

    props.actionClient.editItem(
      {
        item: state.itemToEdit.id,
        pinelist: props.pinelistId,
        newTitle: state.title,
        newDetails: state.details,
        isSection: state.isSection,
      },
      observer
    );
  };

  return (
    <EditItemContext.Provider
      value={{
        state: state,
        actions: {
          changeTitle,
          changeDetails,
          setEditing,
          submit,
          stopEditing,
        },
      }}
    >
      {props.children}
    </EditItemContext.Provider>
  );
};
