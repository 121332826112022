import { Error } from "../domain/Error";

export type UpdateEmailEvent = "CLICK_UPDATE" | "ON_SUCCESS" | "ON_ERROR";
export type UpdateEmailError = "GENERIC";

const remove = (error: UpdateEmailError, errors: UpdateEmailError[]): UpdateEmailError[] => {
  return errors.filter((it) => it !== error);
};

const add = (error: UpdateEmailError, errors: UpdateEmailError[]): UpdateEmailError[] => {
  return [error, ...errors];
};

export const UpdateEmailErrorDecider = (
  event: UpdateEmailEvent,
  currentErrors: UpdateEmailError[],
  value: string,
  error: Error | null
): UpdateEmailError[] => {
  let newErrors = currentErrors;
  switch (event) {
    case "CLICK_UPDATE":
      newErrors = remove("GENERIC", newErrors);
      break;
    case "ON_SUCCESS":
      newErrors = [];
      break;
    case "ON_ERROR":
      newErrors = add("GENERIC", newErrors);
      break;
  }

  return newErrors;
};
