import React, { ReactElement, useEffect, useMemo } from "react";
import { AppBar, Badge, Button, Divider, Icon, MenuItem, Toolbar } from "@material-ui/core";
import { Link, navigate } from "@reach/router";
import pineLogo from "../pine-logo.svg";
import { UserContext } from "../App";
import { UserActionType } from "../UserContext";
import { ExtraWideStyledMenu, StyledMenu } from "./StyledMenu";
import { Notification } from "../domain/types";
import { ActionClient } from "../domain/Client";
import { useMountEffect } from "../helpers";
import dayjs from "dayjs";
import { getRelativeTime } from "../domain/getRelativeTime";

interface Props {
  actionClient: ActionClient;
  disableNotifications?: boolean;
  notificationsOverride?: Notification[];
}

export const UserTopBar = (props: Props): ReactElement => {
  const { state, dispatch } = React.useContext(UserContext);
  const [userAnchorEl, setUserAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notifAnchorEl, setNotifAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const unreadNotificationCount = useMemo(() => notifications.filter((it) => !it.read).length, [
    notifications,
  ]);
  const maxNotifications = 8;

  useMountEffect(() => {
    props.actionClient.getNotifications({
      onSuccess: setNotifications,
      onError: () => {},
    });
  });

  useMountEffect(() => {
    setInterval(() => {
      props.actionClient.getNotifications({
        onSuccess: setNotifications,
        onError: () => {},
      });
    }, 5 * 60 * 1000);
  });

  useEffect(() => {
    if (props.notificationsOverride) {
      setNotifications(props.notificationsOverride);
    }
  }, [props.notificationsOverride, setNotifications]);

  const handleLogout = (): void => {
    if (state.user) {
      navigate("/").then(() => {
        dispatch({ type: UserActionType.LOGOUT, user: undefined });
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("token");
      });
    }
  };

  const handleUserClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleUserClose = (): void => {
    setUserAnchorEl(null);
  };

  const handleNotifClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setNotifAnchorEl(event.currentTarget);
  };

  const handleNotifClose = (): void => {
    setNotifAnchorEl(null);
  };

  const visitNotification = (notification: Notification): void => {
    navigate(notification.link);
    handleNotifClose();
    props.actionClient.markAsRead([notification.id], {
      onSuccess: (notifications: Notification[]) => setNotifications(notifications),
      onError: () => {},
    });
  };

  const getNotifications = (): ReactElement[] => {
    if (notifications.length === 0) {
      return [
        <MenuItem key={0} style={{ whiteSpace: "normal", justifyContent: "center" }}>
          <div>No notifications yet</div>
        </MenuItem>,
      ];
    }

    const mostRecent = notifications
      .sort((a, b) => (dayjs(b.timestamp).isBefore(dayjs(a.timestamp)) ? -1 : 1))
      .slice(0, maxNotifications)
      .map((it) => (
        <div key={it.id}>
          <MenuItem
            onClick={(): void => visitNotification(it)}
            style={{ whiteSpace: "normal", backgroundColor: it.read ? "#f5f5f5" : "white" }}
          >
            <div className="fdr fac fjc width-100">
              <div
                className="text-primary"
                style={{ fontSize: "4.5rem", lineHeight: "1rem", visibility: it.read ? "hidden" : "visible" }}
              >
                &#183;
              </div>
              <div className="pvxs mld mrd" dangerouslySetInnerHTML={{ __html: it.text }} />
              <div className="mla text-grey text-s" style={{ whiteSpace: "nowrap" }}>
                {getRelativeTime(it.timestamp)}
              </div>
            </div>
          </MenuItem>
          <Divider />
        </div>
      ));

    const viewAllButton = (
      <MenuItem
        key="button"
        onClick={(): void => {
          navigate("/account/notifications");
        }}
        style={{ whiteSpace: "normal", backgroundColor: "white" }}
      >
        <Button style={{ padding: 0 }} className="bg-light-grey" color="primary">
          view all notifications
        </Button>
      </MenuItem>
    );

    return notifications.length > maxNotifications ? [...mostRecent, viewAllButton] : mostRecent;
  };

  const notificationsBell = (): ReactElement => {
    if (props.disableNotifications) {
      return (
        <Button
          aria-label="notifications"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleNotifClick}
          disabled={true}
          style={{ backgroundColor: "#C1DFD5", color: "#047F57" }}
        >
          <Badge badgeContent={unreadNotificationCount} color="primary" overlap="rectangular">
            <Icon>notifications</Icon>
          </Badge>
        </Button>
      );
    }

    return (
      <Button
        aria-label="notifications"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleNotifClick}
      >
        <Badge badgeContent={unreadNotificationCount} color="primary" overlap="rectangular">
          <Icon>notifications</Icon>
        </Badge>
      </Button>
    );
  };

  return (
    <>
      <AppBar position="sticky" color="default">
        <Toolbar disableGutters={true} className="container container-when-large">
          <Link to="/home" className="no-link-format">
            <div className="fdr fac">
              <img data-testid="pine-icon" style={{ height: "3rem" }} src={pineLogo} alt="" />
              <h1 className="text-xl font-logo">Pinelist</h1>
            </div>
          </Link>
          <div className="mla fdr fac">
            {notificationsBell()}
            <Button
              aria-label="menu"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleUserClick}
            >
              <span className="text-l mrs bold non-caps">{state.user?.username}</span>
              <Icon>account_circle</Icon>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className="bg-almond width-100 pvs text-s">
        <div className="align-center">
          This app is in beta.{" "}
          <a
            className="text-dark"
            href="https://forms.gle/Exiv1FZT2fSKhb3w9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Let us know what you think.
          </a>
        </div>
      </div>
      <StyledMenu anchorEl={userAnchorEl} keepMounted open={Boolean(userAnchorEl)} onClose={handleUserClose}>
        <MenuItem
          onClick={(): void => {
            navigate("/account-settings");
          }}
        >
          Account settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </StyledMenu>
      <ExtraWideStyledMenu
        anchorEl={notifAnchorEl}
        keepMounted
        open={Boolean(notifAnchorEl)}
        onClose={handleNotifClose}
      >
        {getNotifications()}
      </ExtraWideStyledMenu>
    </>
  );
};
