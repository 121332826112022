import React, { ReactElement, ReactNode } from "react";
import { EmptyState } from "../components/EmptyState";
import { Divider, List } from "@material-ui/core";
import { LineItem } from "../pinelists/LineItem";
import { User } from "../domain/types";

type SummaryItem = {
  name: string;
  id: string;
  owner?: User;
};

interface Props {
  items: SummaryItem[];
  emptyStateText: string;
  header: string;
  url: (id: string) => string;
  nameDisplayFn?: (name: string) => ReactNode;
  showOwner?: boolean;
}

export const ProfileSection = (props: Props): ReactElement => {
  const nameDisplayFn = props.nameDisplayFn || ((name: string): string => name);

  return (
    <div className="col-sm-6 ">
      <h2 className="text-xl mtl">{props.header}</h2>
      {props.items.length === 0 && <EmptyState header="Nothing here yet!" body={props.emptyStateText} />}
      <List>
        {props.items.map((item) => (
          <div key={item.id}>
            <LineItem
              key={item.id}
              textPrimary={nameDisplayFn(item.name)}
              textSecondary=""
              href={props.url(item.id)}
              section="not-section"
              commentCount={undefined}
              claimedBy={undefined}
              ownedBy={props.showOwner ? item.owner : undefined}
              actions={[]}
            />
            <Divider />
          </div>
        ))}
      </List>
    </div>
  );
};
