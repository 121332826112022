import React, { ReactElement, useState } from "react";
import { Button, Icon, TextField } from "@material-ui/core";
import { ifEnterKey } from "../../helpers";
import { Comment, CommentVisibility } from "../../domain/types";
import { ActionClient } from "../../domain/Client";

interface Props {
  actionClient: ActionClient;
  giverView: boolean;
  itemId: string;
  pinelistId: string;
  setCommentCallback: (comment: Comment) => void;
  allowSwitchingVisibility: boolean;
}

export const AddCommentField = (props: Props): ReactElement => {
  const [comment, setComment] = useState<string>("");
  const [commentVisibility, setCommentVisibility] = useState<CommentVisibility>(
    props.giverView ? "GIVERS_ONLY" : "EVERYONE"
  );

  const submitComment = (): void => {
    props.actionClient.addComment(
      {
        text: comment,
        item: props.itemId,
        pinelist: props.pinelistId,
        visibility: commentVisibility,
      },
      {
        onSuccess: (result: Comment) => {
          props.setCommentCallback(result);
          setComment("");
        },
        onError: () => {},
      }
    );
  };

  const switchVisibility = (): void => {
    if (commentVisibility === "EVERYONE") {
      setCommentVisibility("GIVERS_ONLY");
    } else if (commentVisibility === "GIVERS_ONLY") {
      setCommentVisibility("EVERYONE");
    }
  };

  return (
    <>
      <TextField
        margin="dense"
        id={`add-comment-inline`}
        name={`add-comment-inline`}
        label="Add comment"
        type="text"
        variant="filled"
        fullWidth
        style={{ marginTop: ".75rem", marginBottom: 0 }}
        onChange={(e): void => setComment(e.target.value)}
        onKeyDown={(e): void => ifEnterKey(e, submitComment)}
        placeholder=""
        value={comment}
        inputProps={{ "aria-label": "Add comment" }}
        InputProps={{
          endAdornment: (
            <Button color="primary" onClick={submitComment} data-testid="send-comment">
              <Icon>send</Icon>
            </Button>
          ),
        }}
      />
      {props.giverView && (
        <div className="text-xs text-rust bg-text-field-grey pas fdr fac">
          <Icon fontSize="small">{commentVisibility === "EVERYONE" ? "visibility" : "visibility_off"}</Icon>
          {commentVisibility === "EVERYONE"
            ? "This will be visible to everyone"
            : "This will only be visible to other givers"}
          {props.allowSwitchingVisibility && (
            <div>
              <button className="underline" onClick={switchVisibility}>
                {commentVisibility === "EVERYONE" ? "Change to givers-only" : "Change to everyone"}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
