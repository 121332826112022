import React, { ReactElement, useContext } from "react";
import { Icon, IconButton, Menu, MenuItem } from "@material-ui/core";
import { Comment, User } from "../../domain/types";
import { Username } from "../../components/Username";
import { getRelativeTime } from "../../domain/getRelativeTime";
import { UserContext } from "../../App";

interface Props {
  comment: Comment;
  owner: User;
  deleteComment: (commentId: string) => void;
}

export const CommentBox = (props: Props): ReactElement => {
  const bg = props.comment.commenter.id === props.owner.id ? "bg-light-green" : "bg-light-grey";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { state } = useContext(UserContext);

  const isCommentAuthorCurrentUser = (): boolean => {
    return state.user?.id === props.comment.commenter.id;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div key={props.comment.timestamp} className={`${bg} bradm pad mbs`}>
      <div>
        {props.comment.visibility === "GIVERS_ONLY" && (
          <div className="text-xs fdr fac text-rust mbs">
            <Icon fontSize="small">visibility_off</Icon>
            This is only visible to givers
          </div>
        )}
        <div className="fdr">
          <a className="no-link-format mbs" href={`/profile/${props.comment.commenter.id}`}>
            <Username username={props.comment.commenter.username} />
          </a>
          <div className="text-s mla">{getRelativeTime(props.comment.timestamp)}</div>
          <div
            style={{ marginTop: "-14px", visibility: isCommentAuthorCurrentUser() ? "visible" : "hidden" }}
          >
            <IconButton className="" onClick={handleClick}>
              <Icon fontSize="small">more_vert</Icon>
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem aria-label="delete" onClick={(): void => props.deleteComment(props.comment.id)}>
                <Icon data-testid={`delete-comment-${props.comment.id}`}>delete</Icon>
                <span className="mhd all-caps">Delete</span>
              </MenuItem>
            </Menu>
          </div>
        </div>
        {props.comment.text}
      </div>
    </div>
  );
};
